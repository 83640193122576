/*===========================
		COMMON css
===========================*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&amp;display=swap");
html {
  scroll-behavior: smooth; }

body {
  font-family: "Inter", sans-serif !important;
  font-weight: normal;
  font-size: .8rem;
  font-style: normal;
  color: #5d657b;
  overflow-x: hidden;
  background: #f1f5f9; }

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

a:focus,
input:focus,
textarea:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

a:hover {
  color: #4a6cf7; }

button,
a {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }

a,
a:focus,
a:hover {
  text-decoration: none; }

i,
span,
a {
  display: inline-block; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit; }

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none; }

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  margin: 0px; }

.img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%; }

.para-width-500 {
  max-width: 500px;
  width: 100%; }

.unstyled a.active {
  color: #4a6cf7;
  border-color: rgba(74, 108, 247, 0.15);
  background: rgba(74, 108, 247, 0.1);
}
    
@media (max-width: 767px) {
  .container {
    padding: 0 30px; } }

/* ========== cart style ========== */
.card-style {
  background: #fff;
  box-sizing: border-box;
  padding: 25px 30px;
  position: relative;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 10px 20px rgba(200, 208, 216, 0.3);
  border-radius: 10px; }
  @media (max-width: 767px) {
    .card-style {
      padding: 20px; } }
  .card-style .jvm-zoom-btn {
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    right: 30px;
    bottom: 30px;
    cursor: pointer; }
    .card-style .jvm-zoom-btn.jvm-zoomin {
      bottom: 70px; }
  .card-style .dropdown-toggle {
    border: none;
    background: none; }
    .card-style .dropdown-toggle::after {
      display: none; }
  .card-style .dropdown-menu {
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07); }
    .card-style .dropdown-menu li:hover a {
      color: #4a6cf7 !important; }
    .card-style .dropdown-menu li a {
      display: block;
      font-size: 14px; }


/* My Styles */
.page-item.active .page-link  {
  background-color: "#252063" !important;
  color: "#ffffff",
}
/* ======= Border Radius ========= */
.radius-4 {
  border-radius: 4px; }

.radius-10 {
  border-radius: 10px; }

.radius-30 {
  border-radius: 30px; }

.radius-50 {
  border-radius: 50px; }

.radius-full {
  border-radius: 50%; }

.scroll-top {
  width: 45px;
  height: 45px;
  background: #4a6cf7;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #fff;
  border-radius: 5px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }
  .scroll-top:hover {
    color: #fff;
    background: rgba(74, 108, 247, 0.8); }

.form-control:focus {
  box-shadow: none; }

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus,
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus,
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus,
.form-check-input:focus,
.radio-style.radio-success .form-check-input:focus,
.radio-style.radio-warning .form-check-input:focus,
.radio-style.radio-danger .form-check-input:focus {
  box-shadow: none; }

.hover-underline:hover {
  text-decoration: underline; }

/* ========= Theme Change Css ============= */
.darkTheme {
  color: rgba(255, 255, 255, 0.7); }
  .darkTheme .text-gray {
    color: rgba(255, 255, 255, 0.5) !important; }
  .darkTheme .card-style {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); }
    .darkTheme .card-style .dropdown-menu {
      background: #2f3546; }

/* ============= typography css ============= */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #262d3f;
  margin: 0; }

h1,
.h1 {
  font-size: 32px;
  font-weight: 700; }

h2,
.h2 {
  font-size: 28px;
  font-weight: 600; }

h3,
.h3 {
  font-size: 24px;
  font-weight: 500; }

h4,
.h4 {
  font-size: 20px;
  font-weight: 600; }

h5,
.h5 {
  font-size: 16px;
  font-weight: 700; }

h6,
.h6 {
  font-size: 16px;
  font-weight: 600; }

.text-bold {
  font-weight: 700; }

.text-semi-bold {
  font-weight: 600; }

.text-medium {
  font-weight: 500; }

.text-regular {
  font-weight: 400; }

.text-light {
  font-weight: 300; }

.text-sm {
  font-size: 14px;
  line-height: 22px; }

/* ========== breadcrumb ============ */
.breadcrumb-wrapper {
  display: flex;
  justify-content: flex-end; }
  @media (max-width: 767px) {
    .breadcrumb-wrapper {
      justify-content: flex-start; } }
  .breadcrumb-wrapper .breadcrumb li {
    font-size: 14px;
    color: #4a6cf7; }
    .breadcrumb-wrapper .breadcrumb li a {
      color: #5d657b; }
      .breadcrumb-wrapper .breadcrumb li a:hover {
        color: #4a6cf7; }

/* =========== Theme Change Css ============== */
.darkTheme h1,
.darkTheme h2,
.darkTheme h3,
.darkTheme h4,
.darkTheme h5,
.darkTheme h6,
.darkTheme .h1,
.darkTheme .h2,
.darkTheme .h3,
.darkTheme .h4,
.darkTheme .h5,
.darkTheme .h6 {
  color: #fff; }

.darkTheme .text-dark {
  color: rgba(255, 255, 255, 0.7) !important; }

.darkTheme .breadcrumb-wrapper .breadcrumb li a {
  color: rgba(255, 255, 255, 0.5); }

.darkTheme .update-image label {
  color: #2f3546; }

/* ========== Buttons css ========== */
/* buttons base styles */
.main-btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 15px 45px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  overflow: hidden; }
  .main-btn:hover {
    color: inherit; }

.btn-sm {
  padding: 10px 20px;
  font-weight: 400; }

/* buttons hover effect */
.btn-hover {
  position: relative;
  overflow: hidden; }
  .btn-hover::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 0%;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.05);
    top: 50%;
    left: 50%;
    padding: 50%;
    z-index: -1;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    -webkit-transform: translate3d(-50%, -50%, 0) scale(0);
    -moz-transform: translate3d(-50%, -50%, 0) scale(0);
    -ms-transform: translate3d(-50%, -50%, 0) scale(0);
    -o-transform: translate3d(-50%, -50%, 0) scale(0);
    transform: translate3d(-50%, -50%, 0) scale(0); }
  .btn-hover:hover::after {
    -webkit-transform: translate3d(-50%, -50%, 0) scale(1.3);
    -moz-transform: translate3d(-50%, -50%, 0) scale(1.3);
    -ms-transform: translate3d(-50%, -50%, 0) scale(1.3);
    -o-transform: translate3d(-50%, -50%, 0) scale(1.3);
    transform: translate3d(-50%, -50%, 0) scale(1.3); }

/* primary buttons */
.primary-btn {
  background: #4a6cf7;
  color: #fff; }
  .primary-btn:hover {
    color: #fff; }

.primary-btn-outline {
  background: transparent;
  color: #4a6cf7;
  border-color: #4a6cf7; }
  .primary-btn-outline:hover {
    color: #fff;
    background: #4a6cf7; }

/* secondary buttons */
.secondary-btn {
  background: #00c1f8;
  color: #fff; }
  .secondary-btn:hover {
    color: #fff; }

.secondary-btn-outline {
  background: transparent;
  color: #00c1f8;
  border-color: #00c1f8; }
  .secondary-btn-outline:hover {
    color: #fff;
    background: #00c1f8; }

/* success buttons */
.success-btn {
  background: #219653;
  color: #fff; }
  .success-btn:hover {
    color: #fff; }

.success-btn-outline {
  background: transparent;
  color: #219653;
  border-color: #219653; }
  .success-btn-outline:hover {
    color: #fff;
    background: #219653; }

/* danger buttons */
.danger-btn {
  background: #d50100;
  color: #fff; }
  .danger-btn:hover {
    color: #fff; }

.danger-btn-outline {
  background: transparent;
  color: #d50100;
  border-color: #d50100; }
  .danger-btn-outline:hover {
    color: #fff;
    background: #d50100; }

/* warning buttons */
.warning-btn {
  background: #f7c800;
  color: #fff; }
  .warning-btn:hover {
    color: #fff; }

.warning-btn-outline {
  background: transparent;
  color: #f7c800;
  border-color: #f7c800; }
  .warning-btn-outline:hover {
    color: #fff;
    background: #f7c800; }

/* info buttons */
.info-btn {
  background: #97ca31;
  color: #fff; }
  .info-btn:hover {
    color: #fff; }

.info-btn-outline {
  background: transparent;
  color: #97ca31;
  border-color: #97ca31; }
  .info-btn-outline:hover {
    color: #fff;
    background: #97ca31; }

/* dark buttons */
.dark-btn {
  background: #262d3f;
  color: #fff; }
  .dark-btn:hover {
    color: #fff; }

.dark-btn-outline {
  background: transparent;
  color: #262d3f;
  border-color: #262d3f; }
  .dark-btn-outline:hover {
    color: #fff;
    background: #262d3f; }

/* light buttons */
.light-btn {
  background: #efefef;
  color: #262d3f; }
  .light-btn:hover {
    color: #262d3f; }

.light-btn-outline {
  background: transparent;
  color: #262d3f;
  border-color: #efefef; }
  .light-btn-outline:hover {
    color: #262d3f;
    background: #efefef; }

/* active buttons */
.active-btn {
  background: #4a6cf7;
  color: #fff; }
  .active-btn:hover {
    color: #fff; }

.active-btn-outline {
  background: transparent;
  color: #4a6cf7;
  border-color: #4a6cf7; }
  .active-btn-outline:hover {
    color: #fff;
    background: #4a6cf7; }

/* deactive buttons */
.deactive-btn {
  background: #cbe1ff;
  color: #4a6cf7; }
  .deactive-btn:hover {
    color: #4a6cf7; }

.deactive-btn-outline {
  background: transparent;
  color: #4a6cf7;
  border-color: #cbe1ff; }
  .deactive-btn-outline:hover {
    color: #4a6cf7;
    background: #cbe1ff; }

/* =========  square-btn ========= */
.square-btn {
  border-radius: 0px; }

/* =========  rounded-md ========= */
.rounded-md {
  border-radius: 10px; }

/* =========  rounded-full ========= */
.rounded-full {
  border-radius: 30px; }

/* ========== buttons group css ========= */
.buttons-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }
  .buttons-group li {
    margin: 10px; }

/* ====== Status Button ====== */
.status-btn {
  padding: 7px 15px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 400; }
  .status-btn.primary-btn {
    color: #fff;
    background: #4a6cf7; }
  .status-btn.active-btn {
    color: #4a6cf7;
    background: rgba(74, 108, 247, 0.1); }
  .status-btn.close-btn {
    color: #d50100;
    background: rgba(213, 1, 0, 0.1); }
  .status-btn.warning-btn {
    color: #f7c800;
    background: rgba(247, 200, 0, 0.1); }
  .status-btn.info-btn {
    color: #97ca31;
    background: rgba(151, 202, 49, 0.1); }
  .status-btn.success-btn {
    color: #219653;
    background: rgba(33, 150, 83, 0.1); }
  .status-btn.secondary-btn {
    color: #00c1f8;
    background: rgba(0, 193, 248, 0.1); }
  .status-btn.dark-btn {
    color: #262d3f;
    background: rgba(38, 45, 63, 0.1); }
  .status-btn.orange-btn {
    color: #f2994a;
    background: rgba(242, 153, 74, 0.1); }

/* ======= Theme Change Css =========== */
.darkTheme .dark-btn {
  background: #2f3546; }

.darkTheme .dark-btn-outline {
  border-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5); }

.darkTheme .light-btn-outline {
  color: #efefef; }

/* ============ alerts css ============ */
.alert-box {
  display: flex;
  position: relative;
  margin-bottom: 20px; }
  @media (max-width: 767px) {
    .alert-box {
      padding-left: 0px !important; } }
  .alert-box .left {
    max-width: 75px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: #d50100;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 767px) {
      .alert-box .left {
        display: none; } }
    .alert-box .left h5 {
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
      color: #fff; }
  .alert-box .alert {
    margin-bottom: 0px;
    padding: 25px 40px; }
    @media (max-width: 767px) {
      .alert-box .alert {
        padding: 20px; } }

/* Alert Primary */
.primary-alert .left {
  background: #4a6cf7; }

.primary-alert .alert {
  color: #4a6cf7;
  border: 1px solid #4a6cf7;
  background: rgba(74, 108, 247, 0.2);
  width: 100%; }
  .primary-alert .alert .alert-heading {
    color: #4a6cf7;
    margin-bottom: 15px; }

/* Alert Danger */
.danger-alert .left {
  background: #d50100; }

.danger-alert .alert {
  color: #d50100;
  border: 1px solid #d50100;
  background: rgba(213, 1, 0, 0.2);
  width: 100%; }
  .danger-alert .alert .alert-heading {
    color: #d50100;
    margin-bottom: 15px; }

/* Alert warning */
.warning-alert .left {
  background: #f7c800; }

.warning-alert .alert {
  color: #f7c800;
  border: 1px solid #f7c800;
  background: rgba(247, 200, 0, 0.2);
  width: 100%; }
  .warning-alert .alert .alert-heading {
    color: #f7c800;
    margin-bottom: 15px; }

/* Alert warning */
.warning-alert .left {
  background: #f7c800; }

.warning-alert .alert {
  color: #f7c800;
  border: 1px solid #f7c800;
  background: rgba(247, 200, 0, 0.2);
  width: 100%; }
  .warning-alert .alert .alert-heading {
    color: #f7c800;
    margin-bottom: 15px; }

/* Alert info */
.info-alert .left {
  background: #97ca31; }

.info-alert .alert {
  color: #97ca31;
  border: 1px solid #97ca31;
  background: rgba(151, 202, 49, 0.2);
  width: 100%; }
  .info-alert .alert .alert-heading {
    color: #97ca31;
    margin-bottom: 15px; }

/* Alert success */
.success-alert .left {
  background: #219653; }

.success-alert .alert {
  color: #219653;
  border: 1px solid #219653;
  background: rgba(33, 150, 83, 0.2);
  width: 100%; }
  .success-alert .alert .alert-heading {
    color: #219653;
    margin-bottom: 15px; }

/* Alert secondary */
.secondary-alert .left {
  background: #00c1f8; }

.secondary-alert .alert {
  color: #00c1f8;
  border: 1px solid #00c1f8;
  background: rgba(0, 193, 248, 0.2);
  width: 100%; }
  .secondary-alert .alert .alert-heading {
    color: #00c1f8;
    margin-bottom: 15px; }

/* Alert gray */
.gray-alert .left {
  background: #5d657b; }

.gray-alert .alert {
  color: #5d657b;
  border: 1px solid #5d657b;
  background: rgba(93, 101, 123, 0.2);
  width: 100%; }
  .gray-alert .alert .alert-heading {
    color: #5d657b;
    margin-bottom: 15px; }

/* Alert black */
.black-alert .left {
  background: #000; }

.black-alert .alert {
  color: #000;
  border: 1px solid #000;
  background: rgba(0, 0, 0, 0.2);
  width: 100%; }
  .black-alert .alert .alert-heading {
    color: #000;
    margin-bottom: 15px; }

/* Alert orange */
.orange-alert .left {
  background: #f2994a; }

.orange-alert .alert {
  color: #f2994a;
  border: 1px solid #f2994a;
  background: rgba(242, 153, 74, 0.2);
  width: 100%; }
  .orange-alert .alert .alert-heading {
    color: #f2994a;
    margin-bottom: 15px; }

/* ========== cards css =========== */
/* card-style-1 */
.card-style-1 {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 25px 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }
  .card-style-1:hover {
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); }
  .card-style-1 .card-meta {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 30px; }
    @media (max-width: 767px) {
      .card-style-1 .card-meta {
        padding: 0 20px; } }
    .card-style-1 .card-meta .image {
      max-width: 40px;
      width: 100%;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px; }
      .card-style-1 .card-meta .image img {
        width: 100%; }
    .card-style-1 .card-meta .text p {
      color: #262d3f; }
      .card-style-1 .card-meta .text p a {
        color: inherit; }
        .card-style-1 .card-meta .text p a:hover {
          color: #4a6cf7; }
  .card-style-1 .card-image {
    border-radius: 10px;
    margin-bottom: 25px;
    overflow: hidden; }
    .card-style-1 .card-image a {
      display: block; }
    .card-style-1 .card-image img {
      width: 100%; }
  .card-style-1 .card-content {
    padding: 0px 30px; }
    @media (max-width: 767px) {
      .card-style-1 .card-content {
        padding: 0px 20px; } }
    .card-style-1 .card-content h4 a {
      color: inherit;
      margin-bottom: 15px;
      display: block; }
      .card-style-1 .card-content h4 a:hover {
        color: #4a6cf7; }

/* card-style-2 */
.card-style-2 {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 20px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }
  .card-style-2:hover {
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); }
  .card-style-2 .card-image {
    border-radius: 4px;
    margin-bottom: 30px;
    overflow: hidden; }
    .card-style-2 .card-image a {
      display: block; }
    .card-style-2 .card-image img {
      width: 100%; }
  .card-style-2 .card-content {
    padding: 0px 10px; }
    @media (max-width: 767px) {
      .card-style-2 .card-content {
        padding: 0px; } }
    .card-style-2 .card-content h4 a {
      color: inherit;
      margin-bottom: 15px;
      display: block; }
      .card-style-2 .card-content h4 a:hover {
        color: #4a6cf7; }

/* card-style-3 */
.card-style-3 {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 25px 30px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }
  .card-style-3:hover {
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); }
  .card-style-3 .card-content h4 a {
    color: inherit;
    margin-bottom: 15px;
    display: block; }
    .card-style-3 .card-content h4 a:hover {
      color: #4a6cf7; }
  .card-style-3 .card-content a.read-more {
    font-weight: 500;
    color: #262d3f;
    margin-top: 20px; }
    .card-style-3 .card-content a.read-more:hover {
      color: #4a6cf7;
      letter-spacing: 2px; }

/* card-style-4 */
.card-style-4 {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 20px 30px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }
  .card-style-4:hover {
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); }
  .card-style-4 .card-image {
    border-radius: 50%;
    margin: auto;
    margin-bottom: 30px;
    max-width: 250px;
    width: 100%;
    overflow: hidden; }
    .card-style-4 .card-image a {
      display: block; }
    .card-style-4 .card-image img {
      width: 100%; }
  .card-style-4 .card-content {
    margin-bottom: 10px; }
    .card-style-4 .card-content h4 a {
      color: inherit;
      margin-bottom: 15px;
      display: block; }
      .card-style-4 .card-content h4 a:hover {
        color: #4a6cf7; }
    .card-style-4 .card-content a.read-more {
      font-weight: 500;
      color: #262d3f;
      margin-top: 20px; }
      .card-style-4 .card-content a.read-more:hover {
        color: #4a6cf7;
        letter-spacing: 2px; }

/* card-style-5 */
.card-style-5 {
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  align-items: center;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }
  .card-style-5:hover {
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); }
  @media (max-width: 767px) {
    .card-style-5 {
      display: block; } }
  @media only screen and (min-width: 550px) and (max-width: 767px) {
    .card-style-5 {
      display: flex; } }
  .card-style-5 .card-image {
    margin: auto;
    margin-right: 20px;
    border-radius: 4px;
    max-width: 180px;
    width: 100%;
    overflow: hidden; }
    @media (max-width: 767px) {
      .card-style-5 .card-image {
        margin-right: auto;
        margin-bottom: 20px;
        max-width: 100%; } }
    @media only screen and (min-width: 550px) and (max-width: 767px) {
      .card-style-5 .card-image {
        margin-right: 20px;
        margin-bottom: 0px;
        max-width: 180px; } }
    .card-style-5 .card-image a {
      display: block; }
    .card-style-5 .card-image img {
      width: 100%; }
      @media (max-width: 767px) {
        .card-style-5 .card-image img {
          width: 100%; } }
  .card-style-5 .card-content {
    margin-bottom: 10px; }
    .card-style-5 .card-content h4 a {
      color: inherit;
      margin-bottom: 15px;
      display: block; }
      .card-style-5 .card-content h4 a:hover {
        color: #4a6cf7; }
    .card-style-5 .card-content .main-btn {
      margin-top: 20px; }

/* ======= card-style-6 ======== */
.card-style-6 {
  padding: 25px 30px;
  background: #fff;
  border: 1px solid #efefef;
  border-radius: 4px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }
  .card-style-6:hover {
    -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); }
  @media only screen and (min-width: 1200px) and (max-width: 1399px), (max-width: 767px) {
    .card-style-6 {
      padding: 20px; } }
  .card-style-6 .card-image {
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 15px; }
    .card-style-6 .card-image a {
      display: block; }
      .card-style-6 .card-image a img {
        width: 100%; }
  .card-style-6 .card-action {
    display: flex; }
    .card-style-6 .card-action .action {
      display: flex;
      align-items: center; }
      .card-style-6 .card-action .action a {
        color: #5d657b;
        font-size: 14px; }
        .card-style-6 .card-action .action a:hover {
          text-decoration: underline; }
      .card-style-6 .card-action .action button {
        border: none;
        background: none;
        display: flex;
        color: #262d3f;
        margin-right: 10px;
        font-size: 16px; }
        .card-style-6 .card-action .action button:hover {
          color: #4a6cf7; }

/* ======= icon-card ======== */
.icon-card {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 30px 20px;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 10px 20px rgba(200, 208, 216, 0.3);
  border-radius: 10px; }
  .icon-card.icon-card-3 {
    display: block;
    padding: 0px; }
    .icon-card.icon-card-3 .card-content {
      display: flex;
      padding: 20px;
      padding-bottom: 0; }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .icon-card h6 {
      font-size: 15px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .icon-card h3 {
      font-size: 20px; } }
  .icon-card.icon-card-2 {
    display: block; }
    .icon-card.icon-card-2 .progress {
      height: 7px; }
      .icon-card.icon-card-2 .progress .progress-bar {
        border-radius: 4px; }
  .icon-card .icon {
    max-width: 46px;
    width: 100%;
    height: 46px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin-right: 20px;
    background: rgba(74, 108, 247, 0.1);
    color: #4a6cf7;
    font-weight: 700; }
    @media only screen and (min-width: 1200px) and (max-width: 1399px) {
      .icon-card .icon {
        margin-right: 10px; } }
    .icon-card .icon.purple {
      background: rgba(155, 81, 224, 0.1);
      color: #9b51e0; }
    .icon-card .icon.success {
      background: rgba(33, 150, 83, 0.1);
      color: #219653; }
    .icon-card .icon.primary {
      background: rgba(74, 108, 247, 0.1);
      color: #4a6cf7; }
    .icon-card .icon.orange {
      background: rgba(242, 153, 74, 0.1);
      color: #f2994a; }
    .icon-card .icon.opacity-100.purple {
      background: #9b51e0;
      color: #fff; }
    .icon-card .icon.opacity-100.success {
      background: #219653;
      color: #fff; }
    .icon-card .icon.opacity-100.primary {
      background: #4a6cf7;
      color: #fff; }
    .icon-card .icon.opacity-100.orange {
      background: #f2994a;
      color: #fff; }
    .icon-card .icon.opacity-100.deep-blue {
      background: #345d9d;
      color: #fff; }

.card-wrapper {
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding: 25px 15px; }
  .card-wrapper .single-card-wrapper {
    padding: 0px 15px; }
  .card-wrapper .single-card {
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: 30px;
    max-width: 330px;
    min-width: 270px;
    width: 100%; }
    .card-wrapper .single-card .bg-shape {
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      z-index: -1; }
    .card-wrapper .single-card h3 {
      font-weight: bold;
      font-size: 24px;
      color: #fff;
      margin-bottom: 36px; }
    .card-wrapper .single-card .card-info {
      display: flex;
      align-items: center; }
      .card-wrapper .single-card .card-info span {
        font-weight: 500;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6); }
      .card-wrapper .single-card .card-info p {
        font-weight: 500;
        font-size: 12px;
        color: #fff; }

/* ========= Theme Change Css =========== */
.darkTheme .card-style-6,
.darkTheme .card-style-1 {
  background: #262d3f;
  border-color: #2f3546; }
  .darkTheme .card-style-6 .card-meta .text p,
  .darkTheme .card-style-1 .card-meta .text p {
    color: #e5e5e5; }
  .darkTheme .card-style-6 .card-action .action button,
  .darkTheme .card-style-1 .card-action .action button {
    color: #efefef; }
    .darkTheme .card-style-6 .card-action .action button:hover,
    .darkTheme .card-style-1 .card-action .action button:hover {
      color: #4a6cf7; }

.darkTheme .icon-card,
.darkTheme .card-style-5,
.darkTheme .card-style-2 {
  background: #262d3f;
  border-color: #2f3546;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2); }

.darkTheme .card-style-4,
.darkTheme .card-style-3 {
  background: #262d3f;
  border-color: #2f3546; }
  .darkTheme .card-style-4 .card-content a.read-more,
  .darkTheme .card-style-3 .card-content a.read-more {
    color: #e5e5e5; }
    .darkTheme .card-style-4 .card-content a.read-more:hover,
    .darkTheme .card-style-3 .card-content a.read-more:hover {
      color: #4a6cf7; }

/* =========== tabs css ============== */
/* ==== tab style 1 ==== */
.tab-style-1 nav {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px; }
  .tab-style-1 nav button {
    border: none;
    background: transparent;
    margin-right: 40px;
    padding-bottom: 15px;
    position: relative;
    font-weight: 500;
    color: #5d657b; }
    .tab-style-1 nav button::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -1.5px;
      width: 0;
      height: 3px;
      background: #4a6cf7;
      border-radius: 5px;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
    .tab-style-1 nav button:hover, .tab-style-1 nav button.active {
      color: #4a6cf7; }
      .tab-style-1 nav button:hover::after, .tab-style-1 nav button.active::after {
        width: 100%; }

.tab-style-1 .tab-content p {
  font-size: 14px; }

/* ==== tab style 2 ==== */
.tab-style-2 nav {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px; }
  .tab-style-2 nav button {
    border: none;
    background: transparent;
    margin-right: 20px;
    padding: 10px 14px;
    position: relative;
    font-weight: 500;
    color: #5d657b; }
    .tab-style-2 nav button::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 0;
      height: 1px;
      background: #4a6cf7;
      border-radius: 5px;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
    .tab-style-2 nav button:hover, .tab-style-2 nav button.active {
      color: #4a6cf7;
      background: rgba(74, 108, 247, 0.1); }
      .tab-style-2 nav button:hover::after, .tab-style-2 nav button.active::after {
        width: 100%; }

.tab-style-2 .tab-content p {
  font-size: 14px; }

/* ==== tab style 3 ==== */
.tab-style-3 nav {
  margin-bottom: 20px; }
  .tab-style-3 nav button {
    margin-right: 20px;
    padding: 10px 16px;
    position: relative;
    font-weight: 500;
    font-size: 16px; }
    .tab-style-3 nav button:hover, .tab-style-3 nav button.active {
      color: #fff;
      background: #4a6cf7; }
    @media only screen and (min-width: 1200px) and (max-width: 1399px) {
      .tab-style-3 nav button {
        margin-right: 10px; } }
    @media (max-width: 767px) {
      .tab-style-3 nav button {
        margin-right: 5px;
        font-size: 14px;
        padding: 8px 14px; } }
    @media only screen and (min-width: 1200px) and (max-width: 1399px), (max-width: 767px) {
      .tab-style-3 nav button i {
        margin-right: 2px; } }

.tab-style-3 .tab-content p {
  font-size: 14px; }

/* ==== tab style 4 ==== */
.tab-style-4 nav {
  margin-bottom: 10px;
  display: inline-flex;
  background: #efefef;
  padding: 6px;
  border-radius: 4px; }
  .tab-style-4 nav button {
    margin-right: 20px;
    padding: 10px 14px;
    position: relative;
    font-weight: 500;
    border: none;
    background: transparent;
    border-radius: 4px;
    color: #5d657b; }
    .tab-style-4 nav button:hover, .tab-style-4 nav button.active {
      color: #fff;
      background: #4a6cf7; }
    @media (max-width: 767px) {
      .tab-style-4 nav button {
        margin-right: 12px; } }

.tab-style-4 .tab-content p {
  font-size: 14px; }

/* ==== tab style 5 ==== */
.tab-style-5 {
  display: flex; }
  .tab-style-5 nav {
    background: #fff;
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 4px;
    margin-right: 30px;
    height: 100%; }
    .tab-style-5 nav button {
      width: 50px;
      height: 50px;
      position: relative;
      font-weight: 700;
      border: none;
      background: transparent;
      border-radius: 4px;
      color: #5d657b;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px; }
      .tab-style-5 nav button::after {
        content: "";
        position: absolute;
        right: -54px;
        top: 50%;
        -webkit-transform: translateY(-50%) rotate(45deg);
        -moz-transform: translateY(-50%) rotate(45deg);
        -ms-transform: translateY(-50%) rotate(45deg);
        -o-transform: translateY(-50%) rotate(45deg);
        transform: translateY(-50%) rotate(45deg);
        width: 35px;
        height: 35px;
        background: #fff;
        border: 1px solid #efefef;
        border-right: 0px;
        border-top: 0px;
        display: none; }
      .tab-style-5 nav button:hover, .tab-style-5 nav button.active {
        color: #fff;
        background: #4a6cf7; }
      .tab-style-5 nav button.active::after {
        display: block; }
  .tab-style-5 .tab-content p {
    font-size: 14px; }

/* ==== tab style 6 ==== */
.tab-style-6 nav {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px; }
  .tab-style-6 nav button {
    border: none;
    background: transparent;
    margin-right: 40px;
    padding-bottom: 15px;
    position: relative;
    font-weight: 500;
    color: #5d657b; }
    .tab-style-6 nav button:hover, .tab-style-6 nav button.active {
      color: #4a6cf7; }

.tab-style-6 .tab-content p {
  font-size: 14px; }

/* ========= Theme Change Css ============= */
.darkTheme .tab-style-1 nav,
.darkTheme .tab-style-2 nav,
.darkTheme .tab-style-6 nav {
  border-color: rgba(239, 239, 239, 0.2); }
  .darkTheme .tab-style-1 nav button,
  .darkTheme .tab-style-2 nav button,
  .darkTheme .tab-style-6 nav button {
    color: #fff; }
    .darkTheme .tab-style-1 nav button:hover, .darkTheme .tab-style-1 nav button.active,
    .darkTheme .tab-style-2 nav button:hover,
    .darkTheme .tab-style-2 nav button.active,
    .darkTheme .tab-style-6 nav button:hover,
    .darkTheme .tab-style-6 nav button.active {
      color: #4a6cf7; }

.darkTheme .tab-style-3 nav button {
  background: rgba(47, 53, 70, 0.8); }
  .darkTheme .tab-style-3 nav button:hover, .darkTheme .tab-style-3 nav button.active {
    color: #fff;
    background: #4a6cf7; }

.darkTheme .tab-style-4 nav {
  background: #2f3546; }
  .darkTheme .tab-style-4 nav button {
    color: rgba(255, 255, 255, 0.5); }
    .darkTheme .tab-style-4 nav button.active, .darkTheme .tab-style-4 nav button:hover {
      color: #fff; }

.darkTheme .tab-style-5 nav {
  background: #262d3f;
  border-color: #2f3546; }
  .darkTheme .tab-style-5 nav button::after {
    background: #262d3f;
    border-color: #2f3546; }

/* =========== tables css =========== */
.table {
  border-collapse: inherit;
  border-spacing: 0px; }
  .table > :not(caption) > * > * {
    padding: 15px 0;
    border-bottom-color: #efefef;
    vertical-align: middle; }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: #efefef; }
  .table tbody tr:first-child > * {
    padding-top: 20px; }
  .table tbody tr:last-child > * {
    border-bottom-color: transparent;
    padding-bottom: 0px; }
  .table th h6 {
    font-weight: 500;
    color: #262d3f;
    font-size: 14px; }
  .table td.min-width {
    padding: 5px; }
    @media (max-width: 767px) {
      .table td.min-width {
        min-width: 150px; } }
  .table td p {
    font-size: 14px;
    line-height: 1.5;
    color: #5d657b; }
    .table td p a {
      color: inherit; }
      .table td p a:hover {
        color: #4a6cf7; }
  .table .lead-info {
    min-width: 200px; }
  .table .lead-email {
    min-width: 150px;
    white-space: nowrap; }
  .table .lead-phone {
    min-width: 160px; }
  .table .lead-company {
    min-width: 180px; }
  .table .referrals-image {
    min-width: 150px; }
    .table .referrals-image .image {
      width: 55px;
      max-width: 100%;
      height: 55px;
      border-radius: 4px;
      overflow: hidden; }
      .table .referrals-image .image img {
        width: 100%; }
  .table .lead {
    display: flex;
    align-items: center; }
    .table .lead .lead-image {
      max-width: 50px;
      width: 100%;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px; }
      .table .lead .lead-image img {
        width: 100%; }
    .table .lead .lead-text {
      width: 100%; }
  .table .employee-image {
    width: 50px;
    max-width: 100%;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px; }
    .table .employee-image img {
      width: 100%; }
  .table .action {
    display: flex;
    align-items: center; }
    .table .action button {
      border: none;
      background: transparent;
      padding: 0px 6px;
      font-size: 18px; }
      .table .action button.edit:hover {
        color: #4a6cf7; }
      .table .action button::after {
        display: none; }
    .table .action .dropdown-menu {
      -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
      -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07); }
      .table .action .dropdown-menu li:hover a {
        color: #4a6cf7 !important; }
      .table .action .dropdown-menu li a {
        display: block; }

.top-selling-table tr th,
.top-selling-table tr td {
  vertical-align: middle;
  padding: 10px 5px; }

.top-selling-table tr .min-width {
  min-width: 80px;
  white-space: nowrap; }

.top-selling-table .form-check-input[type="checkbox"] {
  margin-left: 5px; }

.top-selling-table .product {
  display: flex;
  align-items: center;
  min-width: 150px; }
  .top-selling-table .product .image {
    border-radius: 4px;
    overflow: hidden;
    margin-right: 15px;
    max-width: 50px;
    width: 100%;
    height: 50px; }
    .top-selling-table .product .image img {
      width: 100%; }
  .top-selling-table .product p {
    width: 100%; }

@media (max-width: 767px) {
  .referrals-table-card .title .right {
    width: 100%; } }

@media only screen and (min-width: 550px) and (max-width: 767px) {
  .referrals-table-card .title .right {
    width: auto; } }

.referrals-table-card .referrals-table td {
  padding: 10px; }

/* ===== lead-table ===== */
.lead-table th,
.lead-table td {
  padding: 10px 5px; }

.lead-table .name {
  min-width: 120px; }

.lead-table .email {
  min-width: 130px; }

.lead-table .project {
  min-width: 150px; }

.lead-table .status {
  min-width: 120px;
  text-align: center; }

.lead-table .action {
  min-width: 60px; }

.clients-table-card .table .employee-info {
  min-width: 150px; }

.clients-table th,
.clients-table td {
  padding: 5px; }
  .clients-table th.min-width,
  .clients-table td.min-width {
    min-width: 150px; }

.clients-table .employee-image {
  margin-right: 0px; }

.table-pagination ul li a {
  width: 32px;
  height: 32px;
  background: #e5e5e5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7); }
  .table-pagination ul li a:hover, .table-pagination ul li a.active {
    background: #4a6cf7;
    color: #fff; }

.table-search form {
  max-width: 270px;
  position: relative; }
  .table-search form input {
    width: 100%;
    border: 1px solid #efefef;
    background: rgba(239, 239, 239, 0.5);
    border-radius: 4px;
    height: 46px;
    padding-left: 44px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
    .table-search form input:focus {
      border-color: #4a6cf7; }
  .table-search form button {
    position: absolute;
    border: none;
    background: transparent;
    left: 16px;
    top: 0;
    height: 46px;
    color: #5d657b; }

.striped-table td,
.striped-table th {
  padding-left: 5px;
  padding-right: 5px; }

.dataTable-pagination li:hover a,
.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
  background: #4a6cf7;
  color: #fff; }

.dataTable-pagination .ellipsis:hover a {
  background: transparent;
  color: #333; }

.dataTable-input:focus {
  border-color: #4a6cf7; }

.activity-card .button-group {
  display: flex;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #efefef; }
  .activity-card .button-group a {
    font-weight: 500;
    font-size: 12px;
    padding: 4px 16px;
    display: inline-block;
    color: #2f3546;
    border-radius: 50px;
    margin: 3px; }
    .activity-card .button-group a.active, .activity-card .button-group a:hover {
      background: #4a6cf7;
      color: #fff; }

.activity-table td {
  min-width: 90px;
  padding: 10px;
  border: none; }
  .activity-table td.time {
    min-width: 110px; }

.activity-table .icon {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; }
  .activity-table .icon .status {
    width: 8px;
    height: 8px;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: -1px;
    right: -1px; }

.activity-table .status-btn {
  font-size: 12px;
  border-radius: 50px;
  border: 1px solid #efefef;
  padding: 4px 14px;
  color: #5d657b;
  min-width: 90px;
  text-align: center; }

.sell-order-table th,
.sell-order-table td {
  min-width: 60px;
  padding: 10px 5px;
  border: none; }

/* ========== Theme Change Css ============= */
.darkTheme .table > :not(caption) > * > * {
  border-bottom-color: rgba(239, 239, 239, 0.2); }

.darkTheme .table > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(239, 239, 239, 0.2); }

.darkTheme .table th h6 {
  color: #fff; }

.darkTheme .table td p {
  color: rgba(255, 255, 255, 0.7); }

.darkTheme .table .action button {
  color: rgba(255, 255, 255, 0.7); }
  .darkTheme .table .action button.edit:hover {
    color: #4a6cf7; }

.darkTheme .table-search form input {
  border-color: #262d3f;
  background: rgba(47, 53, 70, 0.5);
  color: #efefef; }

.darkTheme .table-pagination ul li a {
  background: rgba(47, 53, 70, 0.5);
  color: #efefef; }
  .darkTheme .table-pagination ul li a:hover, .darkTheme .table-pagination ul li a.active {
    background: #4a6cf7;
    color: #fff; }

.darkTheme .activity-card .button-group a {
  color: rgba(239, 239, 239, 0.5); }
  .darkTheme .activity-card .button-group a.active, .darkTheme .activity-card .button-group a:hover {
    color: #fff; }

.darkTheme .dataTable-table th a::after {
  border-bottom-color: rgba(255, 255, 255, 0.7); }

.darkTheme .dataTable-table th a::before {
  border-top-color: rgba(255, 255, 255, 0.7); }

.darkTheme .dataTable-wrapper.no-footer .dataTable-container {
  border-bottom-color: rgba(255, 255, 255, 0.1); }

.darkTheme .dataTable-selector {
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(239, 239, 239, 0.2); }
  .darkTheme .dataTable-selector:focus {
    outline: none; }

.darkTheme .dataTable-input {
  background: #2f3546;
  color: rgba(255, 255, 255, 0.7); }
  .darkTheme .dataTable-input:focus {
    background: #262d3f; }

.darkTheme .dataTable-pagination li a {
  color: rgba(255, 255, 255, 0.5); }

.darkTheme .dataTable-pagination li:hover a,
.darkTheme .dataTable-pagination .active a,
.darkTheme .dataTable-pagination .active a:focus,
.darkTheme .dataTable-pagination .active a:hover {
  background: #4a6cf7;
  color: #fff; }

/* =========== form elements css ========== */
/* ===== input style ===== */
.input-style-1 {
  position: relative;
  margin-bottom: 30px; }
  .input-style-1 label {
    font-size: 14px;
    font-weight: 500;
    color: #262d3f;
    display: block;
    margin-bottom: 10px; }
  .input-style-1 input,
  .input-style-1 textarea {
    width: 100%;
    background: rgba(239, 239, 239, 0.5);
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 10px;
    color: #5d657b;
    resize: none;
    transition: all 0.3s; }
    .input-style-1 input:focus,
    .input-style-1 textarea:focus {
      border-color: #4a6cf7;
      background: #fff; }
    .input-style-1 input[type="date"], .input-style-1 input[type="time"],
    .input-style-1 textarea[type="date"],
    .input-style-1 textarea[type="time"] {
      background: transparent; }

  .input-style-4 select {
    width: 50%;
    background: rgba(239, 239, 239, 0.5);
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: -30px;
    color: #5d657b;
    resize: none;
    transition: all 0.3s; }
    .input-style-1 input:focus,
    .input-style-1 textarea:focus {
      border-color: #4a6cf7;
      background: #fff; }
    .input-style-1 input[type="date"], .input-style-1 input[type="time"],
    .input-style-1 textarea[type="date"],
    .input-style-1 textarea[type="time"] {
      background: transparent; }

.input-style-2 {
  position: relative;
  margin-bottom: 30px;
  z-index: 1; }
  .input-style-2 label {
    font-size: 14px;
    font-weight: 500;
    color: #262d3f;
    display: block;
    margin-bottom: 10px; }
  .input-style-2 input,
  .input-style-2 textarea {
    width: 100%;
    background: rgba(239, 239, 239, 0.5);
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 10px;
    color: #5d657b;
    resize: none;
    transition: all 0.3s; }
    .input-style-2 input:focus,
    .input-style-2 textarea:focus {
      border-color: #4a6cf7;
      background: #fff; }
    .input-style-2 input[type="date"], .input-style-2 input[type="time"],
    .input-style-2 textarea[type="date"],
    .input-style-2 textarea[type="time"] {
      background: transparent; }
  .input-style-2 input[type="date"]::-webkit-inner-spin-button,
  .input-style-2 input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0; }
  .input-style-2 input[type="date"] ~ .icon {
    z-index: -1; }
  .input-style-2 .icon {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 17px; }

.input-style-3 {
  position: relative;
  margin-bottom: 30px; }
  .input-style-3 label {
    font-size: 14px;
    font-weight: 500;
    color: #262d3f;
    display: block;
    margin-bottom: 10px; }
  .input-style-3 input,
  .input-style-3 textarea {
    width: 100%;
    background: rgba(239, 239, 239, 0.5);
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 10px;
    padding-left: 45px;
    color: #5d657b;
    resize: none;
    transition: all 0.3s; }
    .input-style-3 input:focus,
    .input-style-3 textarea:focus {
      border-color: #4a6cf7;
      background: #fff; }
  .input-style-3 .icon {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    padding: 16px; }

/* ========= select style ========== */
.select-style-1 {
  margin-bottom: 30px; }
  .select-style-1 label {
    font-size: 14px;
    font-weight: 500;
    color: #262d3f;
    display: block;
    margin-bottom: 10px; }
  .select-style-1 .select-position {
    position: relative; }
    .select-style-1 .select-position::after {
      border-bottom: 2px solid #5d657b;
      border-right: 2px solid #5d657b;
      content: "";
      display: block;
      height: 10px;
      width: 10px;
      margin-top: -5px;
      pointer-events: none;
      position: absolute;
      right: 16px;
      top: 50%;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out; }
    .select-style-1 .select-position.select-sm::after {
      margin-top: -8px; }
    .select-style-1 .select-position.select-sm select {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px; }
    .select-style-1 .select-position select {
      width: 100%;
      background: transparent;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: 16px;
      padding-right: 38px;
      color: #5d657b;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
      .select-style-1 .select-position select:focus {
        border-color: #4a6cf7;
        outline: none; }
      .select-style-1 .select-position select.light-bg {
        background: rgba(239, 239, 239, 0.5); }
      .select-style-1 .select-position select.light-bg:focus {
        background: #fff; }
      .select-style-1 .select-position select.radius-30 {
        border-radius: 30px; }

.select-style-2 {
  margin-bottom: 30px; }
  .select-style-2 .select-position {
    position: relative; }
    .select-style-2 .select-position.select-sm::after {
      margin-top: -8px; }
    .select-style-2 .select-position.select-sm::before {
      margin-top: 0; }
    .select-style-2 .select-position.select-sm select {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px; }
    .select-style-2 .select-position::before, .select-style-2 .select-position::after {
      content: "";
      display: block;
      height: 8px;
      width: 8px;
      pointer-events: none;
      position: absolute;
      right: 16px;
      top: 50%;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out; }
    .select-style-2 .select-position::before {
      margin-top: 0px;
      border-bottom: 1px solid #5d657b;
      border-right: 1px solid #5d657b; }
    .select-style-2 .select-position::after {
      margin-top: -8px;
      border-top: 1px solid #5d657b;
      border-left: 1px solid #5d657b; }
    .select-style-2 .select-position select {
      width: 100%;
      background: transparent;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: 16px;
      padding-right: 38px;
      color: #5d657b;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
      .select-style-2 .select-position select:focus {
        border-color: #4a6cf7;
        outline: none; }
      .select-style-2 .select-position select.light-bg {
        background: rgba(239, 239, 239, 0.5); }
      .select-style-2 .select-position select.light-bg:focus {
        background: #fff; }
      .select-style-2 .select-position select.select-sm {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 14px; }

.select-style-3 {
  margin-bottom: 30px; }
  .select-style-3 .select-position {
    position: relative; }
    .select-style-3 .select-position::after {
      border-bottom: 2px solid #5d657b;
      border-right: 2px solid #5d657b;
      content: "";
      display: block;
      height: 10px;
      width: 10px;
      margin-top: -7px;
      pointer-events: none;
      position: absolute;
      right: 0px;
      top: 50%;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out; }
    .select-style-3 .select-position.select-sm::after {
      margin-top: -8px; }
    .select-style-3 .select-position.select-sm select {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px; }
    .select-style-3 .select-position select {
      width: 100%;
      background: transparent;
      border: transparent;
      border-radius: 10px;
      padding-right: 38px;
      color: #000;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
      .select-style-3 .select-position select:focus {
        border-color: #4a6cf7;
        outline: none; }
      .select-style-3 .select-position select.light-bg {
        background: rgba(239, 239, 239, 0.5); }

.toggle-switch {
  padding-left: 60px;
  min-height: 30px; }
  .toggle-switch .form-check-input {
    width: 50px;
    height: 28px;
    margin-left: -60px;
    cursor: pointer; }
  .toggle-switch label {
    margin-top: 6px;
    font-size: 14px;
    color: #262d3f;
    cursor: pointer;
    user-select: none; }

.checkbox-style {
  padding-left: 40px;
  min-height: 28px; }
  .checkbox-style .form-check-input {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    margin-left: -40px;
    cursor: pointer; }
    .checkbox-style .form-check-input:disabled {
      cursor: auto; }
  .checkbox-style .form-check-input:disabled ~ label {
    cursor: auto; }
  .checkbox-style label {
    margin-top: 6px;
    cursor: pointer;
    user-select: none; }
  .checkbox-style.checkbox-success .form-check-input:checked {
    background-color: #219653;
    border-color: #219653; }
  .checkbox-style.checkbox-warning .form-check-input:checked {
    background-color: #f7c800;
    border-color: #f7c800; }
  .checkbox-style.checkbox-danger .form-check-input:checked {
    background-color: #d50100;
    border-color: #d50100; }

.radio-style {
  padding-left: 40px;
  min-height: 28px; }
  .radio-style .form-check-input {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-left: -40px;
    cursor: pointer; }
    .radio-style .form-check-input:disabled {
      cursor: auto; }
  .radio-style .form-check-input:disabled ~ label {
    cursor: auto; }
  .radio-style label {
    margin-top: 6px;
    cursor: pointer;
    user-select: none; }
  .radio-style.radio-success .form-check-input:checked {
    background-color: #219653;
    border-color: #219653; }
  .radio-style.radio-warning .form-check-input:checked {
    background-color: #f7c800;
    border-color: #f7c800; }
  .radio-style.radio-danger .form-check-input:checked {
    background-color: #d50100;
    border-color: #d50100; }

@media (max-width: 767px) {
  .button-group .main-btn {
    width: 100%; } }

.buy-sell-form .input-group {
  display: flex; }
  .buy-sell-form .input-group input {
    width: 60%;
    background: transparent;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    color: #5d657b; }
    .buy-sell-form .input-group input:focus {
      border-color: #4a6cf7; }
  .buy-sell-form .input-group .select-style-1 {
    width: 40%; }
    .buy-sell-form .input-group .select-style-1 .select-position::after {
      width: 8px;
      height: 8px; }
  .buy-sell-form .input-group select {
    border: 1px solid #e2e8f0;
    border-radius: 0px 4px 4px 0px;
    padding: 8px 16px;
    padding-right: 24px;
    font-size: 14px;
    color: #5d657b; }

.buy-sell-form .buy-sell-btn .main-btn {
  display: block;
  width: 100%;
  font-weight: 500; }
  .buy-sell-form .buy-sell-btn .main-btn:hover {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1); }
  .buy-sell-form .buy-sell-btn .main-btn.success-btn {
    background: #08c18d; }
  .buy-sell-form .buy-sell-btn .main-btn.danger-btn {
    background: #eb5757; }

.buy-sell-form .field-group-2 label {
  font-size: 12px; }

.buy-sell-form .field-group-2 .input-group input {
  font-size: 12px;
  width: 70%; }

.buy-sell-form .field-group-2 .input-group span {
  font-size: 12px;
  padding: 8px 16px;
  width: 30%;
  background: #e2e8f0;
  text-align: center;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #e2e8f0; }

.buy-sell-form .input-group-2 label {
  font-size: 12px;
  color: #5d657b;
  margin-bottom: 8px;
  display: block; }

.buy-sell-form .input-group-2 .select-position::after {
  width: 8px;
  height: 8px; }

.buy-sell-form .input-group-2 select {
  padding: 8px 12px;
  font-size: 12px;
  color: #5d657b;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  width: 100%; }

/* ========== Theme Change Css =========== */
.darkTheme .input-style-1 label,
.darkTheme .input-style-2 label,
.darkTheme .input-style-3 label {
  color: #fff; }

.darkTheme .input-style-1 input,
.darkTheme .input-style-1 textarea,
.darkTheme .input-style-2 input,
.darkTheme .input-style-2 textarea,
.darkTheme .input-style-3 input,
.darkTheme .input-style-3 textarea {
  background: rgba(47, 53, 70, 0.2);
  border-color: #2f3546;
  color: rgba(255, 255, 255, 0.7); }
  .darkTheme .input-style-1 input:focus,
  .darkTheme .input-style-1 textarea:focus,
  .darkTheme .input-style-2 input:focus,
  .darkTheme .input-style-2 textarea:focus,
  .darkTheme .input-style-3 input:focus,
  .darkTheme .input-style-3 textarea:focus {
    background: #262d3f;
    border-color: #efefef; }
  .darkTheme .input-style-1 input::placeholder,
  .darkTheme .input-style-1 textarea::placeholder,
  .darkTheme .input-style-2 input::placeholder,
  .darkTheme .input-style-2 textarea::placeholder,
  .darkTheme .input-style-3 input::placeholder,
  .darkTheme .input-style-3 textarea::placeholder {
    color: rgba(255, 255, 255, 0.5); }

.darkTheme .select-style-1 label,
.darkTheme .select-style-2 label,
.darkTheme .select-style-3 label {
  color: #fff; }

.darkTheme .select-style-1 select,
.darkTheme .select-style-2 select,
.darkTheme .select-style-3 select {
  border-color: #2f3546;
  color: rgba(255, 255, 255, 0.5); }
  .darkTheme .select-style-1 select:focus,
  .darkTheme .select-style-2 select:focus,
  .darkTheme .select-style-3 select:focus {
    background: #262d3f;
    border-color: #efefef; }
  .darkTheme .select-style-1 select.light-bg,
  .darkTheme .select-style-2 select.light-bg,
  .darkTheme .select-style-3 select.light-bg {
    background: #2f3546; }
  .darkTheme .select-style-1 select.light-bg:focus,
  .darkTheme .select-style-2 select.light-bg:focus,
  .darkTheme .select-style-3 select.light-bg:focus {
    background: #262d3f;
    border-color: #efefef; }

.darkTheme .select-style-3 select {
  color: #fff; }

.darkTheme .toggle-switch label {
  color: #fff; }

.darkTheme .checkbox-style .form-check-input {
  border-color: rgba(239, 239, 239, 0.5);
  background-color: transparent; }
  .darkTheme .checkbox-style .form-check-input:checked {
    background-color: #4a6cf7;
    border-color: #4a6cf7; }

.darkTheme .checkbox-style label {
  color: #fff; }

.darkTheme .checkbox-style.checkbox-success .form-check-input:checked {
  background-color: #219653;
  border-color: #219653; }

.darkTheme .checkbox-style.checkbox-warning .form-check-input:checked {
  background-color: #f7c800;
  border-color: #f7c800; }

.darkTheme .checkbox-style.checkbox-danger .form-check-input:checked {
  background-color: #d50100;
  border-color: #d50100; }

.darkTheme .radio-style .form-check-input {
  background-color: transparent;
  border-color: rgba(239, 239, 239, 0.5); }
  .darkTheme .radio-style .form-check-input:checked {
    background-color: #4a6cf7;
    border-color: #4a6cf7; }

.darkTheme .radio-style label {
  color: #fff; }

.darkTheme .radio-style.radio-success .form-check-input:checked {
  background-color: #219653;
  border-color: #219653; }

.darkTheme .radio-style.radio-warning .form-check-input:checked {
  background-color: #f7c800;
  border-color: #f7c800; }

.darkTheme .radio-style.radio-danger .form-check-input:checked {
  background-color: #d50100;
  border-color: #d50100; }

.darkTheme .field-group-2 .input-group input {
  border-color: #efefef;
  color: rgba(255, 255, 255, 0.7); }

.darkTheme .field-group-2 .input-group span {
  background: #e2e8f0;
  color: #262d3f;
  border: 1px solid #e2e8f0; }

.darkTheme .input-group-2 select {
  border-color: #efefef; }

.darkTheme .field-group .input-group input {
  color: rgba(255, 255, 255, 0.7); }

.darkTheme .field-group .select-style-1 select {
  border-color: #efefef; }

/* ========== Form Editors ========= */
.form-editor-wrapper .ql-toolbar.ql-snow {
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 0px 0px; }

.form-editor-wrapper .ql-container.ql-snow {
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 4px 4px; }
  .form-editor-wrapper .ql-container.ql-snow .ql-editor {
    min-height: 350px; }

.form-editor-wrapper .ql-bubble .ql-tooltip {
  z-index: 999; }

/* ======= Theme Change ========== */
.darkTheme .ql-editor.ql-blank::before {
  color: rgba(239, 239, 239, 0.5); }

.darkTheme .ql-snow .ql-picker {
  color: rgba(255, 255, 255, 0.5); }

.darkTheme .ql-snow .ql-formats .ql-stroke {
  stroke: rgba(255, 255, 255, 0.5); }

.darkTheme .ql-snow .ql-formats .ql-fill {
  fill: rgba(255, 255, 255, 0.5); }

/* ============= notification css ============= */
.single-notification {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
  border-bottom: 1px solid #efefef; }
  .single-notification.readed {
    opacity: 0.7; }
  .single-notification:first-child {
    padding-top: 0px; }
  .single-notification:last-child {
    padding-bottom: 0px;
    border-bottom: 0px; }
  .single-notification .checkbox {
    max-width: 50px;
    width: 100%;
    padding-top: 10px; }
    @media (max-width: 767px) {
      .single-notification .checkbox {
        display: none; } }
    .single-notification .checkbox input {
      background-color: #efefef;
      border-color: #e5e5e5; }
      .single-notification .checkbox input:checked {
        background-color: #4a6cf7;
        border-color: #4a6cf7; }
  .single-notification .notification {
    display: flex;
    width: 100%; }
    @media (max-width: 767px) {
      .single-notification .notification {
        flex-direction: column; } }
    .single-notification .notification .image {
      max-width: 50px;
      width: 100%;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      margin-right: 15px; }
      @media (max-width: 767px) {
        .single-notification .notification .image {
          margin-bottom: 15px; } }
      .single-notification .notification .image img {
        width: 100%; }
    .single-notification .notification .content {
      display: block;
      max-width: 800px; }
      .single-notification .notification .content h6 {
        margin-bottom: 15px; }
      .single-notification .notification .content p {
        margin-bottom: 10px; }
  .single-notification .action {
    display: inline-flex;
    justify-content: flex-end;
    padding-top: 10px; }
    @media (max-width: 767px) {
      .single-notification .action {
        display: none; } }
    .single-notification .action button {
      border: none;
      background: transparent;
      color: #5d657b;
      margin-left: 20px;
      font-size: 18px; }
      .single-notification .action button.delete-btn:hover {
        color: #d50100; }
    .single-notification .action .dropdown-toggle::after {
      display: none; }

/* ========== Theme Change Css ============== */
.darkTheme .single-notification {
  border-color: #2f3546; }

/* ========== header css ========== */
.header {
  padding: 30px 0;
  background: #fff; }
  .header .header-left .menu-toggle-btn .main-btn {
    padding: 0px 15px;
    height: 46px;
    line-height: 46px;
    border-radius: 10px; }
  .header .header-left .header-search form {
    max-width: 270px;
    position: relative; }
    .header .header-left .header-search form input {
      width: 100%;
      border: 1px solid #efefef;
      background: rgba(239, 239, 239, 0.5);
      border-radius: 10px;
      height: 46px;
      padding-left: 44px;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s; }
      .header .header-left .header-search form input:focus {
        border-color: #4a6cf7;
        background: #fff; }
    .header .header-left .header-search form button {
      position: absolute;
      border: none;
      background: transparent;
      left: 16px;
      top: 0;
      height: 46px;
      color: #5d657b;
      font-weight: 700; }
  .header .header-right {
    display: flex;
    justify-content: flex-end; }
    .header .header-right button {
      border: 1px solid #efefef;
      background: rgba(239, 239, 239, 0.5);
      border-radius: 10px;
      height: 46px;
      width: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative; }
      .header .header-right button::after {
        display: none; }
      .header .header-right button span {
        position: absolute;
        width: 20px;
        height: 20px;
        background: #4a6cf7;
        color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -8px;
        right: -6px;
        font-size: 12px;
        font-weight: 500; }
    .header .header-right .dropdown-menu {
      width: 350px;
      border: 1px solid #efefef;
      padding: 10px 10px;
      -webkit-transition: all 0.3s ease-out 0s;
      -moz-transition: all 0.3s ease-out 0s;
      -ms-transition: all 0.3s ease-out 0s;
      -o-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s;
      top: 24px !important;
      right: 0;
      position: absolute;
      transform: translate3d(0px, 60px, 0px);
      border-radius: 10px; }
      .header .header-right .dropdown-menu li {
        padding: 3px 0px;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
        border-bottom: 1px solid #efefef;
        position: relative;
        z-index: 2; }
        .header .header-right .dropdown-menu li:hover a {
          color: #4a6cf7;
          background: rgba(74, 108, 247, 0.05); }
        .header .header-right .dropdown-menu li:last-child {
          border-bottom: none; }
        .header .header-right .dropdown-menu li a {
          padding: 8px 12px;
          display: flex;
          color: rgba(0, 0, 0, 0.7);
          border-radius: 6px; }
          .header .header-right .dropdown-menu li a .image {
            max-width: 35px;
            width: 100%;
            height: 35px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 12px; }
            .header .header-right .dropdown-menu li a .image img {
              width: 100%; }
          .header .header-right .dropdown-menu li a .content {
            width: 100%; }
            .header .header-right .dropdown-menu li a .content h6 {
              font-size: 14px;
              margin-bottom: 5px;
              font-weight: 600;
              line-height: 1; }
            .header .header-right .dropdown-menu li a .content p {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.7);
              margin-bottom: 0px;
              line-height: 1.4; }
            .header .header-right .dropdown-menu li a .content span {
              font-size: 12px;
              color: rgba(0, 0, 0, 0.5); }
    .header .header-right .dropdown-box {
      position: relative; }
    .header .header-right .notification-box,
    .header .header-right .header-message-box {
      position: relative; }
    .header .header-right .notification-box .dropdown-menu.dropdown-menu-end {
      transform: translate3d(0px, 60px, 0px); }
    .header .header-right .header-message-box .dropdown-menu.dropdown-menu-end {
      transform: translate3d(0px, 60px, 0px); }
    .header .header-right .profile-box {
      display: flex;
      position: relative; }
      .header .header-right .profile-box button {
        width: auto; }
      .header .header-right .profile-box .dropdown-menu {
        width: 230px; }
        .header .header-right .profile-box .dropdown-menu.dropdown-menu-end {
          transform: translate3d(0px, 60px, 0px); }
        .header .header-right .profile-box .dropdown-menu li {
          border-bottom: none; }
          .header .header-right .profile-box .dropdown-menu li a {
            font-size: 14px;
            display: flex;
            align-items: center; }
            .header .header-right .profile-box .dropdown-menu li a i {
              margin-right: 15px;
              font-weight: 700; }
      .header .header-right .profile-box .profile-info {
        margin: 0 5px; }
        .header .header-right .profile-box .profile-info .info {
          display: flex;
          align-items: center; }
          .header .header-right .profile-box .profile-info .info .image {
            border: 2px solid #f9f9f9;
            -webkit-box-shadow: 0px 21px 25px rgba(218, 223, 227, 0.8);
            -moz-box-shadow: 0px 21px 25px rgba(218, 223, 227, 0.8);
            box-shadow: 0px 21px 25px rgba(218, 223, 227, 0.8);
            width: 46px;
            height: 46px;
            border-radius: 50%;
            margin-left: 16px;
            position: relative; }
            .header .header-right .profile-box .profile-info .info .image .status {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              border: 2px solid #e5e5e5;
              background: #219653;
              position: absolute;
              bottom: 0;
              right: 0;
              top: auto; }
            .header .header-right .profile-box .profile-info .info .image img {
              width: 100%;
              border-radius: 50%; }

/* ========= Dark Theme Css ========== */
.darkTheme .header {
  background: #262d3f; }
  .darkTheme .header .header-search form input {
    background: rgba(47, 53, 70, 0.2);
    border-color: #2f3546;
    color: #efefef; }
    .darkTheme .header .header-search form input:focus {
      background-color: #262d3f;
      border-color: #efefef; }
  .darkTheme .header .header-right button {
    background: rgba(47, 53, 70, 0.2);
    border-color: #2f3546;
    color: #efefef; }
  .darkTheme .header .header-right .dropdown-menu {
    background: #2f3546;
    border-color: #262d3f; }
    .darkTheme .header .header-right .dropdown-menu li {
      border-color: rgba(239, 239, 239, 0.2); }
      .darkTheme .header .header-right .dropdown-menu li a {
        color: #fff; }
        .darkTheme .header .header-right .dropdown-menu li a .content h6 {
          color: #fff; }
        .darkTheme .header .header-right .dropdown-menu li a .content p {
          color: rgba(255, 255, 255, 0.7); }
        .darkTheme .header .header-right .dropdown-menu li a .content span {
          color: rgba(255, 255, 255, 0.6); }
  .darkTheme .header .header-right .profile-info .info h6 {
    color: #fff; }
  .darkTheme .header .header-right .profile-info .info .image {
    border-color: #2f3546;
    -webkit-box-shadow: 0px 21px 25px rgba(38, 45, 63, 0.02);
    -moz-box-shadow: 0px 21px 25px rgba(38, 45, 63, 0.02);
    box-shadow: 0px 21px 25px rgba(38, 45, 63, 0.02); }
    .darkTheme .header .header-right .profile-info .info .image .status {
      border-color: #2f3546; }

/* ========== Dashboards css ================= */
@media (max-width: 767px) {
  #doughnutChart1 {
    height: 250px !important; } }

.legend3 li {
  margin-right: 25px; }
  .legend3 li div {
    white-space: nowrap; }
  .legend3 li .bg-color {
    position: relative;
    margin-left: 12px;
    border-radius: 50%; }
    .legend3 li .bg-color::after {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: inherit;
      left: -12px;
      top: 5px; }
  .legend3 li .text {
    margin-left: 10px; }
    .legend3 li .text p {
      display: flex;
      align-items: center;
      width: 100%; }

.todo-list-wrapper ul li.todo-list-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  margin-bottom: 25px; }
  .todo-list-wrapper ul li.todo-list-item:last-child {
    margin-bottom: 0px; }
  .todo-list-wrapper ul li.todo-list-item::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%; }
  @media (max-width: 767px) {
    .todo-list-wrapper ul li.todo-list-item {
      display: block; }
      .todo-list-wrapper ul li.todo-list-item .todo-status {
        margin-top: 20px; } }
  .todo-list-wrapper ul li.todo-list-item.success::before {
    background: #219653; }
  .todo-list-wrapper ul li.todo-list-item.primary::before {
    background: #4a6cf7; }
  .todo-list-wrapper ul li.todo-list-item.orange::before {
    background: #f2994a; }
  .todo-list-wrapper ul li.todo-list-item.danger::before {
    background: #d50100; }

/* ============ signin css ============= */
.auth-row {
  background: #fff;
  border-radius: 4px;
  overflow: hidden; }

.auth-cover-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px;
  position: relative;
  z-index: 1;
  height: 100%; }
  @media (max-width: 767px) {
    .auth-cover-wrapper {
      padding: 30px 20px; } }
  .auth-cover-wrapper .auth-cover .title {
    text-align: cover;
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      .auth-cover-wrapper .auth-cover .title h1 {
        font-size: 24px; } }
  .auth-cover-wrapper .auth-cover .cover-image {
    max-width: 100%;
    margin: auto; }
    .auth-cover-wrapper .auth-cover .cover-image img {
      width: 100%; }
  .auth-cover-wrapper .auth-cover .shape-image {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 5%; }

.signin-wrapper {
  background: #fff;
  padding: 60px;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .signin-wrapper {
      padding: 40px; } }
  @media (max-width: 767px) {
    .signin-wrapper {
      padding: 30px; } }
  .signin-wrapper .form-wrapper {
    width: 100%; }
  .signin-wrapper .singin-option button {
    font-size: 16px;
    font-weight: 600; }
    @media only screen and (min-width: 1200px) and (max-width: 1399px) {
      .signin-wrapper .singin-option button {
        padding-left: 25px;
        padding-right: 25px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .signin-wrapper .singin-option button {
        padding-left: 30px;
        padding-right: 30px; } }
    @media (max-width: 767px) {
      .signin-wrapper .singin-option button {
        width: 100%; } }
    @media only screen and (min-width: 550px) and (max-width: 767px) {
      .signin-wrapper .singin-option button {
        width: auto; } }
  .signin-wrapper .singin-option a:hover {
    text-decoration: underline; }

/* ============ Theme Change Css ============== */
.darkTheme .auth-row {
  background: #262d3f; }

.darkTheme .signin-wrapper {
  background: #262d3f; }
  .darkTheme .signin-wrapper .danger-btn-outline {
    background: transparent;
    color: rgba(213, 1, 0, 0.8);
    border-color: rgba(213, 1, 0, 0.8); }
    .darkTheme .signin-wrapper .danger-btn-outline:hover {
      color: #fff;
      background: #d50100; }

/* ============ signup css ============= */
.auth-row {
  background: #fff;
  border-radius: 4px;
  overflow: hidden; }

.auth-cover-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px;
  position: relative;
  z-index: 1;
  height: 100%; }
  @media (max-width: 767px) {
    .auth-cover-wrapper {
      padding: 30px 20px; } }
  .auth-cover-wrapper .auth-cover .title {
    text-align: cover;
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      .auth-cover-wrapper .auth-cover .title h1 {
        font-size: 24px; } }
  .auth-cover-wrapper .auth-cover .cover-image {
    max-width: 100%;
    margin: auto; }
    .auth-cover-wrapper .auth-cover .cover-image img {
      width: 100%; }
  .auth-cover-wrapper .auth-cover .shape-image {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 5%; }

.signup-wrapper {
  background: #fff;
  padding: 60px;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .signup-wrapper {
      padding: 40px; } }
  @media (max-width: 767px) {
    .signup-wrapper {
      padding: 30px; } }
  .signup-wrapper .form-wrapper {
    width: 100%; }
  .signup-wrapper .singup-option button {
    font-size: 16px;
    font-weight: 600; }
    @media only screen and (min-width: 1200px) and (max-width: 1399px) {
      .signup-wrapper .singup-option button {
        padding-left: 25px;
        padding-right: 25px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .signup-wrapper .singup-option button {
        padding-left: 30px;
        padding-right: 30px; } }
    @media (max-width: 767px) {
      .signup-wrapper .singup-option button {
        width: 100%; } }
    @media only screen and (min-width: 550px) and (max-width: 767px) {
      .signup-wrapper .singup-option button {
        width: auto; } }
  .signup-wrapper .singup-option a:hover {
    text-decoration: underline; }

/* ============ Theme Change Css ============== */
.darkTheme .auth-row {
  background: #262d3f; }

.darkTheme .signup-wrapper {
  background: #262d3f; }
  .darkTheme .signup-wrapper .danger-btn-outline {
    background: transparent;
    color: rgba(213, 1, 0, 0.8);
    border-color: rgba(213, 1, 0, 0.8); }
    .darkTheme .signup-wrapper .danger-btn-outline:hover {
      color: #fff;
      background: #d50100; }

/* ============ reset-password css ============= */
.auth-row {
  background: #fff;
  border-radius: 4px;
  overflow: hidden; }

.auth-cover-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px;
  position: relative;
  z-index: 1;
  height: 100%; }
  @media (max-width: 767px) {
    .auth-cover-wrapper {
      padding: 30px 20px; } }
  .auth-cover-wrapper .auth-cover .title {
    text-align: cover;
    margin-bottom: 40px; }
    @media (max-width: 767px) {
      .auth-cover-wrapper .auth-cover .title h1 {
        font-size: 24px; } }
  .auth-cover-wrapper .auth-cover .cover-image {
    max-width: 100%;
    margin: auto; }
    .auth-cover-wrapper .auth-cover .cover-image img {
      width: 100%; }
  .auth-cover-wrapper .auth-cover .shape-image {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 5%; }

.reset-password-wrapper {
  background: #fff;
  padding: 60px;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .reset-password-wrapper {
      padding: 40px; } }
  @media (max-width: 767px) {
    .reset-password-wrapper {
      padding: 30px; } }
  .reset-password-wrapper .form-wrapper {
    width: 100%; }

/* ============ Theme Change Css ============== */
.darkTheme .auth-row {
  background: #262d3f; }

.darkTheme .reset-password-wrapper {
  background: #262d3f; }

/* ============ task css ============= */
.task-table.task-completed {
  text-decoration: line-through; }

.task-table .table tr {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }
  .task-table .table tr:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2); }
  .task-table .table tr:last-child > * {
    padding-bottom: 15px; }

.task-table .table td {
  padding: 10px; }
  .task-table .table td.min-width {
    min-width: 150px; }
  .task-table .table td.name-title {
    min-width: 200px; }
  .task-table .table td:first-child > * {
    padding-left: 30px; }
  .task-table .table td:last-child > * {
    padding-right: 30px; }
  .task-table .table td .action .delete-btn:hover {
    color: #d50100 !important; }
  .task-table .table td .action .link-btn:hover {
    color: #4a6cf7 !important; }
  .task-table .table td .action .more-btn:hover {
    color: #4a6cf7; }

/* =========== settings css ============== */
.settings-card-1 .profile-info .profile-image {
  max-width: 75px;
  width: 100%;
  height: 75px;
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
  z-index: 1; }
  .settings-card-1 .profile-info .profile-image img {
    width: 100%;
    border-radius: 50%; }
  .settings-card-1 .profile-info .profile-image .update-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background: #efefef;
    border: 2px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 99; }
    .settings-card-1 .profile-info .profile-image .update-image:hover {
      opacity: 0.9; }
    .settings-card-1 .profile-info .profile-image .update-image input {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
      z-index: 99; }
    .settings-card-1 .profile-info .profile-image .update-image label {
      cursor: pointer;
      z-index: 99; }

/* ============== clients css ============ */
.client-profile-wrapper {
  background: #fff;
  border-radius: 4px; }
  .client-profile-wrapper .client-cover {
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    position: relative;
    height: 160px; }
    .client-profile-wrapper .client-cover img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    .client-profile-wrapper .client-cover .update-image {
      position: absolute;
      right: 30px;
      bottom: 20px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 30px;
      padding: 1px 15px;
      display: inline-block;
      width: auto;
      color: #262d3f;
      z-index: 9999;
      cursor: pointer; }
      .client-profile-wrapper .client-cover .update-image:hover {
        opacity: 0.9; }
      @media (max-width: 767px) {
        .client-profile-wrapper .client-cover .update-image {
          right: 50%;
          bottom: 50%;
          -webkit-transform: translate(50%, 50%);
          -moz-transform: translate(50%, 50%);
          -ms-transform: translate(50%, 50%);
          -o-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
          white-space: nowrap; } }
      .client-profile-wrapper .client-cover .update-image input {
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 99999;
        cursor: pointer; }
      .client-profile-wrapper .client-cover .update-image label {
        z-index: 99;
        cursor: pointer; }
        .client-profile-wrapper .client-cover .update-image label i {
          margin-right: 10px; }
  .client-profile-wrapper .client-profile-photo {
    position: relative;
    margin-top: -80px; }
    @media (max-width: 767px) {
      .client-profile-wrapper .client-profile-photo {
        margin-top: -50px; } }
    .client-profile-wrapper .client-profile-photo .image {
      max-width: 170px;
      width: 100%;
      height: 170px;
      border-radius: 50%;
      position: relative;
      margin: 0 auto;
      border: 6px solid #fff; }
      @media (max-width: 767px) {
        .client-profile-wrapper .client-profile-photo .image {
          max-width: 120px;
          height: 120px; } }
      .client-profile-wrapper .client-profile-photo .image img {
        width: 100%;
        border-radius: 50%; }
      .client-profile-wrapper .client-profile-photo .image .update-image {
        position: absolute;
        right: 0px;
        bottom: 0px;
        border: 2px solid #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background: #efefef;
        color: #262d3f;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        cursor: pointer; }
        .client-profile-wrapper .client-profile-photo .image .update-image:hover {
          opacity: 0.9; }
        .client-profile-wrapper .client-profile-photo .image .update-image input {
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 99;
          cursor: pointer; }
        .client-profile-wrapper .client-profile-photo .image .update-image label {
          z-index: 99;
          cursor: pointer; }
  .client-profile-wrapper .client-info {
    padding: 30px; }
    .client-profile-wrapper .client-info .socials {
      display: flex;
      align-items: center; }
      .client-profile-wrapper .client-info .socials li a {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(239, 239, 239, 0.5);
        border: 1px solid #e5e5e5;
        border-radius: 50%;
        color: #5d657b;
        font-size: 16px;
        margin-right: 20px; }
        .client-profile-wrapper .client-info .socials li a:hover {
          color: #fff;
          background: #4a6cf7;
          border-color: #4a6cf7; }

.clients-table-card .dropdown-toggle {
  border: none;
  background: none; }
  .clients-table-card .dropdown-toggle::after {
    display: none; }

/* ============ Theme Change Css =============== */
.darkTheme .client-profile-wrapper {
  background: #262d3f; }
  .darkTheme .client-profile-wrapper .client-info .socials li a {
    background: rgba(38, 45, 63, 0.5);
    border: 1px solid #2f3546;
    color: #fff; }
    .darkTheme .client-profile-wrapper .client-info .socials li a:hover {
      color: #fff;
      background: #4a6cf7;
      border-color: #4a6cf7; }

/* ============ profile css ============= */
.profile-wrapper {
  background: #fff;
  border-radius: 4px; }
  .profile-wrapper .profile-cover {
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    position: relative;
    height: 160px; }
    .profile-wrapper .profile-cover img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    .profile-wrapper .profile-cover .update-image {
      position: absolute;
      right: 30px;
      bottom: 20px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 30px;
      padding: 1px 15px;
      display: inline-block;
      width: auto;
      color: #262d3f; }
      @media (max-width: 767px) {
        .profile-wrapper .profile-cover .update-image {
          right: 50%;
          bottom: 50%;
          -webkit-transform: translate(50%, 50%);
          -moz-transform: translate(50%, 50%);
          -ms-transform: translate(50%, 50%);
          -o-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
          white-space: nowrap; } }
      .profile-wrapper .profile-cover .update-image input {
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%; }
      .profile-wrapper .profile-cover .update-image label i {
        margin-right: 10px; }
  .profile-wrapper .profile-photo {
    position: relative;
    margin-top: -80px;
    margin-left: 40px;
    margin-right: 40px;
    max-width: 240px;
    width: 100%; }
    @media (max-width: 767px) {
      .profile-wrapper .profile-photo {
        margin-top: -50px;
        margin-left: 20px;
        margin-right: 20px; } }
    .profile-wrapper .profile-photo .image {
      max-width: 170px;
      width: 100%;
      height: 170px;
      border-radius: 50%;
      position: relative;
      border: 6px solid #fff; }
      @media (max-width: 767px) {
        .profile-wrapper .profile-photo .image {
          max-width: 120px;
          height: 120px; } }
      .profile-wrapper .profile-photo .image img {
        width: 100%;
        border-radius: 50%; }
      .profile-wrapper .profile-photo .image .update-image {
        position: absolute;
        right: 0px;
        bottom: 0px;
        border: 2px solid #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background: #efefef;
        color: #262d3f;
        display: flex;
        justify-content: center;
        align-items: center; }
        .profile-wrapper .profile-photo .image .update-image input {
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%; }
  .profile-wrapper .profiles-activities {
    padding-right: 40px; }
    @media (max-width: 767px) {
      .profile-wrapper .profiles-activities {
        padding-left: 20px;
        padding-right: 20px; } }
    .profile-wrapper .profiles-activities .more-btn-wrapper button {
      border: none;
      background: none; }
      @media (max-width: 767px) {
        .profile-wrapper .profiles-activities .more-btn-wrapper button {
          display: none; } }
      .profile-wrapper .profiles-activities .more-btn-wrapper button::after {
        display: none; }
  .profile-wrapper .profile-info {
    margin: 30px;
    padding: 30px 0px;
    border-top: 1px solid #efefef; }
    @media (max-width: 767px) {
      .profile-wrapper .profile-info {
        margin-left: 20px;
        margin-right: 20px; } }
    .profile-wrapper .profile-info p a:hover {
      color: #4a6cf7 !important; }
    .profile-wrapper .profile-info .socials {
      display: flex;
      align-items: center; }
      .profile-wrapper .profile-info .socials li a {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(239, 239, 239, 0.5);
        border: 1px solid #e5e5e5;
        border-radius: 50%;
        color: #5d657b;
        font-size: 16px;
        margin-right: 20px; }
        @media (max-width: 767px) {
          .profile-wrapper .profile-info .socials li a {
            margin-right: 10px; } }
        .profile-wrapper .profile-info .socials li a:hover {
          color: #fff;
          background: #4a6cf7;
          border-color: #4a6cf7; }

/* ========== Theme Change Css ============= */
.darkTheme .profile-wrapper {
  background: #262d3f; }
  .darkTheme .profile-wrapper .profiles-activities ul li p {
    color: #fff; }
  .darkTheme .profile-wrapper .profile-info {
    border-color: #2f3546; }
    .darkTheme .profile-wrapper .profile-info .socials li a {
      background: rgba(38, 45, 63, 0.5);
      border: 1px solid #2f3546;
      color: #fff; }
      .darkTheme .profile-wrapper .profile-info .socials li a:hover {
        color: #fff;
        background: #4a6cf7;
        border-color: #4a6cf7; }
  .darkTheme .profile-wrapper .socials li a {
    background: rgba(38, 45, 63, 0.5);
    border: 1px solid #2f3546;
    color: #fff; }
    .darkTheme .profile-wrapper .socials li a:hover {
      color: #fff;
      background: #4a6cf7;
      border-color: #4a6cf7; }

/* ============ chat css =========== */
.chat-list-card {
  padding: 30px 0; }
  .chat-list-card > .title {
    padding: 0 30px; }
  .chat-list-card form {
    padding: 0 30px; }
    @media (max-width: 767px) {
      .chat-list-card form {
        padding: 0px 20px; } }

.chat-list-wrapper .chat-list {
  padding: 0 30px; }
  @media (max-width: 767px) {
    .chat-list-wrapper .chat-list {
      padding: 0px 20px; } }
  .chat-list-wrapper .chat-list:hover {
    background: rgba(74, 108, 247, 0.1); }
  .chat-list-wrapper .chat-list:last-child {
    border-bottom: 0px; }
    .chat-list-wrapper .chat-list:last-child .chat-list-item {
      border-bottom: 0px solid #efefef; }

.chat-list-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 20px 0; }
  .chat-list-item .image {
    max-width: 35px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    margin-right: 10px; }
    .chat-list-item .image img {
      width: 100%;
      border-radius: 50%; }
    .chat-list-item .image .status {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1.5px solid #fff;
      position: absolute;
      right: 0;
      bottom: 0;
      background: #5d657b; }
      .chat-list-item .image .status.active {
        background: #4a6cf7; }
  .chat-list-item p {
    color: #5d657b;
    line-height: 1.4; }
  .chat-list-item .content {
    width: 100%; }
    .chat-list-item .content .title {
      display: flex;
      justify-content: space-between; }
      .chat-list-item .content .title span {
        font-size: 14px;
        color: #5d657b; }
    .chat-list-item .content button {
      border: none;
      background: transparent;
      margin-left: 15px;
      font-size: 14px; }

/* === === === Message box css === === === */
.message-box-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.message-box {
  display: flex;
  margin-bottom: 20px; }
  .message-box .image {
    max-width: 35px;
    width: 100%;
    height: 35px;
    border-radius: 50%;
    margin-right: 20px; }
    @media (max-width: 767px) {
      .message-box .image {
        margin-right: 10px; } }
    .message-box .image img {
      width: 100%;
      border-radius: 50%; }
  .message-box .content-box {
    border: 1px solid #efefef;
    padding: 12px 30px;
    border-radius: 4px;
    max-width: 550px;
    width: 100%; }
    @media (max-width: 767px) {
      .message-box .content-box {
        padding: 12px; } }
    @media only screen and (min-width: 550px) and (max-width: 767px) {
      .message-box .content-box {
        padding: 12px 18px; } }
    .message-box .content-box .content p {
      font-size: 14px;
      line-height: 22px; }
  .message-box.right {
    flex-direction: row-reverse; }
    .message-box.right .image {
      margin-right: 0px;
      margin-left: 20px; }
      @media (max-width: 767px) {
        .message-box.right .image {
          margin-left: 10px; } }

/* ============ Message Form ============ */
.message-form form {
  display: flex; }
  .message-form form .message-field {
    width: 100%;
    position: relative; }
    .message-form form .message-field .file-emoji {
      position: absolute;
      right: 15px;
      top: 0;
      display: flex; }
      .message-form form .message-field .file-emoji .file-upload {
        width: 40px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1; }
        .message-form form .message-field .file-emoji .file-upload input {
          opacity: 0; }
        .message-form form .message-field .file-emoji .file-upload span {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color: #000;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: -1; }
      .message-form form .message-field .file-emoji button {
        border: 1px solid transparent;
        background: transparent;
        width: 40px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center; }
  .message-form form button.send-btn {
    max-width: 56px;
    width: 100%;
    height: 56px;
    background: #4a6cf7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 18px;
    margin-left: 10px; }
    @media (max-width: 767px) {
      .message-form form button.send-btn {
        display: none; } }
    @media only screen and (min-width: 550px) and (max-width: 767px) {
      .message-form form button.send-btn {
        display: flex; } }

/* ============= Chat about css ============= */
.chat-about .chat-about-profile {
  padding: 20px 0;
  border-bottom: 1px solid #efefef; }
  .chat-about .chat-about-profile .image {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin-bottom: 20px; }
    .chat-about .chat-about-profile .image img {
      width: 100%; }

.chat-about .activity-meta {
  padding-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef; }
  .chat-about .activity-meta ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 12px; }

.chat-about .profile-info {
  padding-top: 20px; }
  .chat-about .profile-info ul li {
    font-size: 14px;
    margin-bottom: 12px; }

.doughnutChart-legend ul {
  display: flex;
  flex-wrap: wrap; }
  .doughnutChart-legend ul li {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    font-size: 14px; }
    .doughnutChart-legend ul li.orange .left::before {
      background: #f2994a; }
    .doughnutChart-legend ul li.danger .left::before {
      background: #d50100; }
    .doughnutChart-legend ul li.warning .left::before {
      background: #f7c800; }
    .doughnutChart-legend ul li .left {
      position: relative;
      padding-left: 20px; }
      .doughnutChart-legend ul li .left::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #4a6cf7;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%); }
    .doughnutChart-legend ul li .right {
      text-align: left;
      padding: 0 10px; }

/* ========== Theme Change Css ============= */
.darkTheme .chat-list-item {
  border-bottom-color: rgba(239, 239, 239, 0.2); }

.darkTheme .message-box .content-box {
  border-color: rgba(239, 239, 239, 0.2); }

.darkTheme .message-form form .message-field .file-emoji button {
  color: #fff; }

.darkTheme .message-form form .message-field .file-emoji .file-upload span {
  color: #fff; }

.darkTheme .chat-about .chat-about-profile {
  border-bottom-color: rgba(239, 239, 239, 0.2); }
  .darkTheme .chat-about .chat-about-profile .content h5 {
    color: #fff; }

.darkTheme .chat-about .activity-meta {
  border-bottom-color: rgba(239, 239, 239, 0.2); }

/* ============= Pricing css =============== */
.monthly-pricing-section .section-title {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center; }
  .monthly-pricing-section .section-title h1 {
    width: 90%;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 767px) {
      .monthly-pricing-section .section-title h1 {
        width: 100%; } }

.single-pricing {
  background: #fff;
  -webkit-box-shadow: 0px 10px 35px rgba(210, 210, 210, 0.17);
  -moz-box-shadow: 0px 10px 35px rgba(210, 210, 210, 0.17);
  box-shadow: 0px 10px 35px rgba(210, 210, 210, 0.17);
  border-radius: 5px;
  padding: 55px 30px;
  text-align: center;
  position: relative; }
  @media (max-width: 767px) {
    .single-pricing {
      margin-bottom: 30px; } }
  @media (min-width: 768px) and (max-width: 850px) {
    .single-pricing {
      padding-left: 15px;
      padding-right: 15px; } }
  .single-pricing.active {
    -webkit-transform: scale(1.05, 1);
    -moz-transform: scale(1.05, 1);
    -ms-transform: scale(1.05, 1);
    -o-transform: scale(1.05, 1);
    transform: scale(1.05, 1);
    z-index: 2;
    padding-top: 125px;
    padding-bottom: 65px; }
    @media (max-width: 767px) {
      .single-pricing.active {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        padding-top: 55px;
        padding-bottom: 55px; } }
    .single-pricing.active .popular {
      position: absolute;
      left: 30px;
      top: 65px;
      padding: 5px 15px;
      border-radius: 30px;
      background: #4a6cf7;
      color: #fff;
      font-size: 14px;
      font-weight: 500; }
      @media (max-width: 767px) {
        .single-pricing.active .popular {
          top: 15px; } }
  .single-pricing .pricing-header h5 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px; }
  .single-pricing .pricing-header h3 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single-pricing .pricing-header h3 {
        font-size: 20px; } }
  .single-pricing .pricing-header .price {
    border-top: 1px solid rgba(93, 101, 123, 0.1);
    border-bottom: 1px solid rgba(93, 101, 123, 0.1);
    margin-bottom: 25px; }
    .single-pricing .pricing-header .price h1 {
      padding: 20px 0px; }
      .single-pricing .pricing-header .price h1 span {
        color: #5d657b; }
  .single-pricing .pricing-body ul {
    margin-bottom: 15px; }
    .single-pricing .pricing-body ul li {
      padding-bottom: 20px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5); }

/* ======= Pricing Table ======= */
.pricing-table {
  text-align: center; }
  .pricing-table thead tr th {
    border-right: 1px solid #efefef;
    min-width: 150px; }
    .pricing-table thead tr th h5 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 15px; }
    .pricing-table thead tr th h1 {
      color: #4a6cf7; }
      .pricing-table thead tr th h1 span {
        font-size: 14px;
        color: #5d657b;
        font-weight: 400; }
  .pricing-table tbody tr td {
    vertical-align: middle;
    border-right: 1px solid #efefef;
    min-width: 150px;
    padding: 20px 10px; }
    .pricing-table tbody tr td:first-child {
      text-align: left; }

/* ========= Theme Change Css ============= */
.darkTheme .single-pricing {
  background: #262d3f;
  -webkit-box-shadow: 0px 10px 35px rgba(47, 53, 70, 0.17);
  -moz-box-shadow: 0px 10px 35px rgba(47, 53, 70, 0.17);
  box-shadow: 0px 10px 35px rgba(47, 53, 70, 0.17); }
  .darkTheme .single-pricing .pricing-body ul li {
    color: rgba(255, 255, 255, 0.5); }

.darkTheme .pricing-table thead tr th {
  border-right-color: rgba(239, 239, 239, 0.2); }

.darkTheme .pricing-table tbody tr td {
  border-right-color: rgba(239, 239, 239, 0.2); }
  .darkTheme .pricing-table tbody tr td p {
    color: #fff; }
    .darkTheme .pricing-table tbody tr td p i {
      color: #fff; }

/* ============ Projects Css =========== */
.project-card .card-body {
  padding: 15px 0px; }
  .project-card .card-body .chart-wrapper {
    display: flex;
    align-items: center; }
    .project-card .card-body .chart-wrapper .chart {
      max-width: 55px;
      width: 100%;
      height: 55px;
      position: relative;
      margin-bottom: 25px;
      margin-right: 20px; }
      .project-card .card-body .chart-wrapper .chart span {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center; }
    .project-card .card-body .chart-wrapper .ldBar-chart {
      position: relative; }
      .project-card .card-body .chart-wrapper .ldBar-chart .baseline {
        stroke: inherit;
        stroke-width: 8px;
        opacity: 0.1; }
      .project-card .card-body .chart-wrapper .ldBar-chart .mainline {
        stroke: inherit;
        stroke-width: 8px; }
      .project-card .card-body .chart-wrapper .ldBar-chart .ldBar-label {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit; }
    .project-card .card-body .chart-wrapper .chart-body {
      width: 250px;
      margin: auto; }
      .project-card .card-body .chart-wrapper .chart-body .ldBar-label {
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        flex-direction: column; }
        .project-card .card-body .chart-wrapper .chart-body .ldBar-label::after {
          content: "Task completed";
          font-size: 14px;
          display: block;
          width: 100%;
          font-weight: 500;
          color: #5d657b; }

.project-card .card-footer .project-image {
  display: flex;
  align-items: center;
  margin-top: 15px; }
  .project-card .card-footer .project-image ul {
    display: flex;
    align-items: center; }
    .project-card .card-footer .project-image ul li {
      width: 28px;
      height: 28px;
      border: 2px solid #fff;
      overflow: hidden;
      margin-left: -10px;
      border-radius: 50%; }
      .project-card .card-footer .project-image ul li:first-child {
        margin-left: 0px; }
      .project-card .card-footer .project-image ul li img {
        width: 100%; }
  .project-card .card-footer .project-image a {
    margin-left: 10px;
    color: #5d657b;
    font-size: 14px; }

.activity-wrapper ul li {
  display: flex;
  padding-bottom: 30px;
  position: relative;
  z-index: 1; }
  .activity-wrapper ul li:last-child {
    padding-bottom: 0px; }
  .activity-wrapper ul li::after {
    content: "";
    width: 2px;
    height: 100%;
    border-left: 1px dashed rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 25px;
    top: 0;
    z-index: -1; }
  @media (max-width: 375px) {
    .activity-wrapper ul li {
      flex-direction: column; }
      .activity-wrapper ul li::after {
        display: none; }
      .activity-wrapper ul li .icon {
        margin-bottom: 10px; }
      .activity-wrapper ul li .content .title {
        flex-wrap: wrap; } }
  .activity-wrapper ul li.primary .icon {
    border-color: rgba(74, 108, 247, 0.1); }
    .activity-wrapper ul li.primary .icon i {
      background: rgba(74, 108, 247, 0.1);
      color: #4a6cf7; }
  .activity-wrapper ul li.danger .icon {
    border-color: rgba(213, 1, 0, 0.1); }
    .activity-wrapper ul li.danger .icon i {
      background: rgba(213, 1, 0, 0.1);
      color: #d50100; }
  .activity-wrapper ul li.success .icon {
    border-color: rgba(33, 150, 83, 0.1); }
    .activity-wrapper ul li.success .icon i {
      background: rgba(33, 150, 83, 0.1);
      color: #219653; }
  .activity-wrapper ul li.orange .icon {
    border-color: rgba(242, 153, 74, 0.1); }
    .activity-wrapper ul li.orange .icon i {
      background: rgba(242, 153, 74, 0.1);
      color: #f2994a; }
  .activity-wrapper ul li .icon {
    max-width: 50px;
    width: 100%;
    height: 50px;
    position: relative;
    border-radius: 50%;
    border: 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    background: #fff;
    font-size: 20px; }
    .activity-wrapper ul li .icon i {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center; }
  .activity-wrapper ul li .content p a {
    color: #5d657b; }

.stroke-primary {
  stroke: #4a6cf7; }

.stroke-success {
  stroke: #219653; }

.stroke-orange {
  stroke: #f2994a; }

.stroke-warning {
  stroke: #f7c800; }

.stroke-info {
  stroke: #97ca31; }

.stroke-danger {
  stroke: #d50100; }

.darkTheme .activity-wrapper ul li::after {
  border-color: rgba(255, 255, 255, 0.1); }

/* =========== Invoice Css ============= */
.invoice-card .invoice-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  @media (max-width: 767px) {
    .invoice-card .invoice-header {
      flex-direction: column; } }
  .invoice-card .invoice-header .invoice-logo {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden; }
    @media (max-width: 767px) {
      .invoice-card .invoice-header .invoice-logo {
        order: -1;
        margin-bottom: 30px; } }
    .invoice-card .invoice-header .invoice-logo img {
      width: 100%; }
  @media (max-width: 767px) {
    .invoice-card .invoice-header .invoice-date {
      margin-top: 30px; } }
  .invoice-card .invoice-header .invoice-date p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px; }
    .invoice-card .invoice-header .invoice-date p span {
      font-weight: 500; }

.invoice-card .invoice-address {
  padding-top: 30px;
  display: flex;
  margin-bottom: 40px; }
  @media (max-width: 767px) {
    .invoice-card .invoice-address {
      display: block; } }
  .invoice-card .invoice-address .address-item {
    margin-right: 30px;
    min-width: 250px; }
    .invoice-card .invoice-address .address-item h5 {
      margin-bottom: 15px; }
    .invoice-card .invoice-address .address-item h1 {
      margin-bottom: 10px;
      font-size: 24px; }
    .invoice-card .invoice-address .address-item p {
      margin-bottom: 10px; }

@media (max-width: 767px) {
  .invoice-card .invoice-action ul li {
    flex: 1; } }

@media (max-width: 767px) {
  .invoice-card .invoice-action ul li a {
    width: 100%; } }

.invoice-table th,
.invoice-table td {
  padding: 10px 8px; }

.invoice-table .service {
  min-width: 150px; }

.invoice-table .desc {
  min-width: 150px; }

.invoice-table .qty {
  min-width: 150px; }

.invoice-table .amount {
  min-width: 100px; }

/* ========== Modals Css ============ */
.warning-modal .warning-card .icon {
  font-size: 120px;
  line-height: 1; }

/* ============== Icons Css ===========*/
.icons-wrapper .icons,
.icons-wrapper ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }
  .icons-wrapper .icons > div,
  .icons-wrapper .icons li,
  .icons-wrapper ul > div,
  .icons-wrapper ul li {
    display: flex;
    align-items: center;
    margin: 10px;
    flex-basis: 215px; }
    @media (max-width: 400px) {
      .icons-wrapper .icons > div,
      .icons-wrapper .icons li,
      .icons-wrapper ul > div,
      .icons-wrapper ul li {
        flex-basis: 100%; } }
    .icons-wrapper .icons > div i,
    .icons-wrapper .icons li i,
    .icons-wrapper ul > div i,
    .icons-wrapper ul li i {
      max-width: 45px;
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #efefef;
      border-radius: 4px;
      background: transparent;
      color: #262d3f;
      font-size: 20px;
      margin-right: 10px; }
    .icons-wrapper .icons > div span,
    .icons-wrapper .icons li span,
    .icons-wrapper ul > div span,
    .icons-wrapper ul li span {
      color: #262d3f;
      user-select: all; }

/* ========= Theme Change Css =========== */
.darkTheme .icons-wrapper .icons > div i,
.darkTheme .icons-wrapper .icons li i,
.darkTheme .icons-wrapper ul > div i,
.darkTheme .icons-wrapper ul li i {
  color: #fff;
  border-color: #5d657b; }

.darkTheme .icons-wrapper .icons > div span,
.darkTheme .icons-wrapper .icons li span,
.darkTheme .icons-wrapper ul > div span,
.darkTheme .icons-wrapper ul li span {
  color: rgba(255, 255, 255, 0.5); }

/* ============ Calendar Css ============= */
.calendar-card .fc {
  height: 450px; }
  .calendar-card .fc#calendar-full {
    height: 600px; }
  .calendar-card .fc table {
    border: none; }
  .calendar-card .fc .fc-toolbar-title {
    font-size: 16px;
    font-weight: 500; }
  .calendar-card .fc .fc-button {
    background: transparent;
    border: none;
    color: #5d657b;
    text-transform: capitalize; }
    .calendar-card .fc .fc-button:focus {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      color: #4a6cf7; }
  .calendar-card .fc th {
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-right: 0px; }
    .calendar-card .fc th a {
      color: #5d657b;
      font-weight: 400; }
  .calendar-card .fc .fc-day {
    border-width: 4px;
    background: #fff; }
    .calendar-card .fc .fc-day.fc-day-today .fc-daygrid-day-frame {
      background: rgba(74, 108, 247, 0.8); }
      .calendar-card .fc .fc-day.fc-day-today .fc-daygrid-day-frame a {
        color: #fff; }
    .calendar-card .fc .fc-day .fc-daygrid-day-frame {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      background: #f9f9f9;
      border-radius: 6px; }
      .calendar-card .fc .fc-day .fc-daygrid-day-frame a {
        color: #5d657b; }

.calendar-card .fc-theme-standard td,
.calendar-card .fc-theme-standard th {
  border-color: transparent; }

/* ========= Theme Change Css ============ */
.darkTheme .calendar-card .fc .fc-button {
  color: #fff; }

.darkTheme .calendar-card .fc th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .darkTheme .calendar-card .fc th a {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400; }

.darkTheme .calendar-card .fc .fc-day {
  padding: 5px;
  background: #262d3f; }
  .darkTheme .calendar-card .fc .fc-day.fc-day-today .fc-daygrid-day-frame {
    background: #4a6cf7; }
    .darkTheme .calendar-card .fc .fc-day.fc-day-today .fc-daygrid-day-frame a {
      color: #fff; }
  .darkTheme .calendar-card .fc .fc-day .fc-daygrid-day-frame {
    background: #2f3546; }
    .darkTheme .calendar-card .fc .fc-day .fc-daygrid-day-frame a {
      color: #efefef; }

/* ========== Option Css ============== */
.option-btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 55px;
  height: 55px;
  background: #4a6cf7;
  font-size: 24px;
  color: #fff;
  border: none;
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  z-index: 99;
  border-radius: 4px; }
  .option-btn i {
    animation: rotate 2s infinite linear; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.option-overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  right: 0;
  top: 0;
  position: fixed;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  z-index: 9999; }
  .option-overlay.show {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }

.option-header {
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px; }
  .option-header h5 {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 4px; }
  .option-header p {
    font-size: 12px;
    line-height: 1.2; }
  .option-header .option-btn-close {
    background: none;
    border: 0px;
    margin-left: 5px; }
    .option-header .option-btn-close i {
      font-weight: 700;
      font-size: 14px; }

.option-box {
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100vh;
  overflow-y: auto;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  padding: 20px;
  z-index: 99999; }
  .option-box.show {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  .option-box li {
    margin-right: 15px; }
    .option-box li:last-child {
      margin-right: 0px; }
    .option-box li button {
      display: block;
      background: none;
      border: none;
      padding: 8px 8px 8px 20px;
      position: relative; }
      .option-box li button::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        border: 2px solid #4a6cf7;
        background: transparent;
        left: 0;
        top: calc(50% - 6px);
        border-radius: 50%; }
      .option-box li button.active {
        color: #4a6cf7; }
        .option-box li button.active::before {
          background: #4a6cf7; }

/* =========== Sidebar css =========== */
.sidebar-nav-wrapper {
  background: #fff;
  width: 250px;
  padding: 20px 0px;
  height: 100vh;
  font-size: .8rem;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0px 0px 30px rgba(200, 208, 216, 0.3);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0); 
}

  /* @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .sidebar-nav-wrapper {
      -webkit-transform: translateX(-260px);
      -moz-transform: translateX(-260px);
      -ms-transform: translateX(-260px);
      -o-transform: translateX(-260px);
      transform: translateX(-260px); 
    } 
  } */

  @media (max-width: 991px) {
    .sidebar-nav-wrapper {
      margin-left: -270px;

      z-index: 9999;
    }
    .open-m {
      margin-left: 0;
    }
  }
  .sidebar-nav-wrapper.active {
    -webkit-transform: translateX(-260px);
    -moz-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    -o-transform: translateX(-260px);
    transform: translateX(-260px); }

    @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
      .sidebar-nav-wrapper.active {
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px); } }
  .sidebar-nav-wrapper .navbar-logo {
    text-align: center;
    padding: 0 25px;
    margin-bottom: 30px; }
  .sidebar-nav-wrapper .sidebar-nav .divider {
    padding: 5px 25px;
    width: 100%; }
    .sidebar-nav-wrapper .sidebar-nav .divider hr {
      height: 1px;
      background: #e2e2e2; }
  .sidebar-nav-wrapper .sidebar-nav ul .nav-item {
    position: relative;
    margin: 5px 0px; }
    .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a {
      color: #262d3f; }
      .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a::before {
        opacity: 1;
        visibility: visible; }
      .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a::after {
        content: "\ea5e";
        font: normal normal normal 1em/1 "LineIcons";
        position: absolute;
        right: 25px;
        top: 16px;
        font-size: .8rem;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg); }
      .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a.collapsed {
        color: #5d657b; }
        .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a.collapsed::before {
          opacity: 0;
          visibility: hidden; }
        .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a.collapsed::after {
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg); }
    .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul {
      padding: 0px 15px; }
      .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li {
        margin-bottom: 10px; }
        .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li:last-child {
          margin-bottom: 0px; }
        .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a {
          font-size: .8rem;
          font-weight: 400;
          border-radius: 6px;
          padding: 8px 15px;
          display: flex;
          align-items: center;
          border: 1px solid transparent; }
          .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a.active, .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a:hover {
            color: #4a6cf7;
            border-color: rgba(74, 108, 247, 0.15);
            background: rgba(74, 108, 247, 0.1); }
          .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a i {
            font-size: 16px;
            margin-right: 15px; }
          .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a span.text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%; }
          .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a span.pro-badge {
            background: #4a6cf7;
            color: #fff;
            padding: 1px 6px;
            border-radius: 4px;
            font-size: 10px;
            margin-left: 10px; }
    .sidebar-nav-wrapper .sidebar-nav ul .nav-item a {
      display: flex;
      align-items: center;
      color: #5d657b;
      font-size: .8rem;
      font-weight: 500;
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 10px 25px; }
      .sidebar-nav-wrapper .sidebar-nav ul .nav-item a::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background: #4a6cf7;
        border-radius: 0 3px 3px 0px;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 0.3s ease-out 0s;
        -moz-transition: all 0.3s ease-out 0s;
        -ms-transition: all 0.3s ease-out 0s;
        -o-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s; }
      .sidebar-nav-wrapper .sidebar-nav ul .nav-item a span.text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%; }
      .sidebar-nav-wrapper .sidebar-nav ul .nav-item a span.pro-badge {
        background: #4a6cf7;
        color: #fff;
        padding: 1px 6px;
        border-radius: 4px;
        font-size: 10px;
        margin-left: 10px; }
      .sidebar-nav-wrapper .sidebar-nav ul .nav-item a .icon {
        margin-right: 12px;
        font-size: 18px; }
        .sidebar-nav-wrapper .sidebar-nav ul .nav-item a .icon svg {
          fill: currentColor; }
    .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a,
    .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a.collapsed, .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a,
    .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a.collapsed {
      color: #262d3f; }
      .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a::before,
      .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a.collapsed::before, .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a::before,
      .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a.collapsed::before {
        opacity: 1;
        visibility: visible; }

.sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children ul li a.active, .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children ul li a:hover {
  color: #fff;
  border-color: #4a6cf7;
  background: #4a6cf7; }
  .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children ul li a.active span.pro-badge, .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children ul li a:hover span.pro-badge {
    background: #fff;
    color: #4a6cf7; }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 11;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden; }
  @media only screen and (min-width: 1400px), only screen and (min-width: 1200px) and (max-width: 1399px) {
    .overlay {
      display: none; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .overlay.active {
      opacity: 1;
      visibility: visible;
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0); } }

.main-wrapper {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-left: 250px;
  min-height: 100vh;
  padding-bottom: 85px;
  position: relative; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .main-wrapper {
      margin-left: 0; } }
  @media (max-width: 767px) {
    .main-wrapper {
      padding-bottom: 110px; } }
  .main-wrapper.active {
    margin-left: 0; }
  .main-wrapper .container-fluid {
    padding-left: 40px;
    padding-right: 40px; }
    @media (max-width: 767px) {
      .main-wrapper .container-fluid {
        padding-left: 20px;
        padding-right: 20px; } }
  .main-wrapper .footer {
    padding: 25px 0;
    justify-items: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%; }
    .main-wrapper .footer .copyright p a {
      color: inherit; }
      .main-wrapper .footer .copyright p a:hover {
        color: #4a6cf7; }
    @media (max-width: 767px) {
      .main-wrapper .footer .terms {
        margin-bottom: 10px;
        text-align: center; } }
    .main-wrapper .footer .terms a {
      color: #5d657b; }
      .main-wrapper .footer .terms a:hover {
        color: #4a6cf7; }

.promo-box {
  box-shadow: 0px 10px 20px rgba(200, 208, 216, 0.3);
  padding: 24px 16px;
  text-align: center;
  max-width: 210px;
  margin: 0 auto;
  margin-top: 32px;
  border-radius: 4px; }
  .promo-box h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px; }
  .promo-box p {
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 16px; }
  .promo-box .main-btn {
    padding: 12px;
    width: 100%; }

/* ====== Theme Change CSS ====== */
.darkTheme .sidebar-nav-wrapper {
  background: #262d3f;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2); }
  .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a {
    color: #fff; }
    .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a.collapsed {
      color: #c2cbd6; }
  .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a.active, .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a:hover {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.15);
    background: rgba(255, 255, 255, 0.1); }
  .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item a {
    color: #c2cbd6; }
  .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a,
  .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a.collapsed, .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a,
  .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a.collapsed {
    color: #fff; }
  .darkTheme .sidebar-nav-wrapper.style-2 {
    background: #090e34; }
    .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children > a {
      color: #fff; }
      .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children > a.collapsed {
        color: #c2cbd6; }
    .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children ul li a.active, .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children ul li a:hover {
      color: #fff;
      border-color: #4a6cf7;
      background: #4a6cf7; }
    .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item a {
      color: #c2cbd6; }
    .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.active > a,
    .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.active > a.collapsed, .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item:hover > a,
    .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item:hover > a.collapsed {
      color: #fff; }

.darkTheme .promo-box {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2); }

/* ========== Layout Change ============ */
.rightSidebar .sidebar-nav-wrapper {
  left: auto;
  right: 0;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0); }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .rightSidebar .sidebar-nav-wrapper {
      -webkit-transform: translateX(260px);
      -moz-transform: translateX(260px);
      -ms-transform: translateX(260px);
      -o-transform: translateX(260px);
      transform: translateX(260px); } }
  .rightSidebar .sidebar-nav-wrapper.active {
    -webkit-transform: translateX(260px);
    -moz-transform: translateX(260px);
    -ms-transform: translateX(260px);
    -o-transform: translateX(260px);
    transform: translateX(260px); }
    @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
      .rightSidebar .sidebar-nav-wrapper.active {
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px); } }

.rightSidebar .overlay {
  left: auto;
  right: 0;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%); }
  .rightSidebar .overlay.active {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }

.rightSidebar .main-wrapper {
  margin-left: auto;
  margin-right: 250px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .rightSidebar .main-wrapper {
      margin-right: 0; } }
  .rightSidebar .main-wrapper.active {
    margin-right: 0; }
  .rightSidebar .main-wrapper .header .row {
    flex-direction: row-reverse; }
  .rightSidebar .main-wrapper .header .header-left {
    flex-direction: row-reverse; }
    .rightSidebar .main-wrapper .header .header-left .menu-toggle-btn {
      margin-right: 0px;
      margin-left: 20px; }
  .rightSidebar .main-wrapper .header .header-right {
    flex-direction: row-reverse; }
    .rightSidebar .main-wrapper .header .header-right .profile-box .info {
      flex-direction: row-reverse; }
      .rightSidebar .main-wrapper .header .header-right .profile-box .info .image {
        margin-right: 16px;
        margin-left: 0; }

/* ========== Theme Change ==========  */
.darkTheme {
  background: #2f3546; }
  .darkTheme .option-box {
    background: #262d3f; }
    .darkTheme .option-box ul li button {
      color: #fff; }
      .darkTheme .option-box ul li button.active {
        color: #4a6cf7; }
  .darkTheme .card-style {
    background: #262d3f;
    border-color: #2f3546; }
    .darkTheme .card-style .title button {
      color: #fff; }
    .darkTheme .card-style .title .dropdown-menu {
      background: #262d3f; }
      .darkTheme .card-style .title .dropdown-menu li:hover {
        background: #2f3546; }
  .darkTheme .dropdown-toggle {
    color: rgba(255, 255, 255, 0.7); }
  .darkTheme .main-wrapper .footer a {
    color: rgba(255, 255, 255, 0.7); }
    .darkTheme .main-wrapper .footer a:hover {
      color: #4a6cf7; }

/* ========== DEFAULT CSS ======== */
/* ======= Margin Top ======= */
.mt-5 {
  margin-top: 5px; }

.mt-10 {
  margin-top: 10px; }

.mt-15 {
  margin-top: 15px; }

.mt-20 {
  margin-top: 20px; }

.mt-25 {
  margin-top: 25px; }

.mt-30 {
  margin-top: 30px; }

.mt-35 {
  margin-top: 35px; }

.mt-40 {
  margin-top: 40px; }

.mt-45 {
  margin-top: 45px; }

.mt-50 {
  margin-top: 50px; }

.mt-55 {
  margin-top: 55px; }

.mt-60 {
  margin-top: 60px; }

.mt-65 {
  margin-top: 65px; }

.mt-70 {
  margin-top: 70px; }

.mt-75 {
  margin-top: 75px; }

.mt-80 {
  margin-top: 80px; }

.mt-85 {
  margin-top: 85px; }

.mt-90 {
  margin-top: 90px; }

.mt-95 {
  margin-top: 95px; }

.mt-100 {
  margin-top: 100px; }

.mt-105 {
  margin-top: 105px; }

.mt-110 {
  margin-top: 110px; }

.mt-115 {
  margin-top: 115px; }

.mt-120 {
  margin-top: 120px; }

.mt-125 {
  margin-top: 125px; }

.mt-130 {
  margin-top: 130px; }

.mt-135 {
  margin-top: 135px; }

.mt-140 {
  margin-top: 140px; }

.mt-145 {
  margin-top: 145px; }

.mt-150 {
  margin-top: 150px; }

.mt-155 {
  margin-top: 155px; }

.mt-160 {
  margin-top: 160px; }

.mt-165 {
  margin-top: 165px; }

.mt-170 {
  margin-top: 170px; }

.mt-175 {
  margin-top: 175px; }

.mt-180 {
  margin-top: 180px; }

.mt-185 {
  margin-top: 185px; }

.mt-190 {
  margin-top: 190px; }

.mt-195 {
  margin-top: 195px; }

.mt-200 {
  margin-top: 200px; }

.mt-205 {
  margin-top: 205px; }

.mt-210 {
  margin-top: 210px; }

.mt-215 {
  margin-top: 215px; }

.mt-220 {
  margin-top: 220px; }

.mt-225 {
  margin-top: 225px; }

/* ======= Margin Bottom ======= */
.mb-5 {
  margin-bottom: 5px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-15 {
  margin-bottom: 15px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-25 {
  margin-bottom: 25px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-35 {
  margin-bottom: 35px; }

.mb-40 {
  margin-bottom: 40px; }

.mb-45 {
  margin-bottom: 45px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-55 {
  margin-bottom: 55px; }

.mb-60 {
  margin-bottom: 60px; }

.mb-65 {
  margin-bottom: 65px; }

.mb-70 {
  margin-bottom: 70px; }

.mb-75 {
  margin-bottom: 75px; }

.mb-80 {
  margin-bottom: 80px; }

.mb-85 {
  margin-bottom: 85px; }

.mb-90 {
  margin-bottom: 90px; }

.mb-95 {
  margin-bottom: 95px; }

.mb-100 {
  margin-bottom: 100px; }

.mb-105 {
  margin-bottom: 105px; }

.mb-110 {
  margin-bottom: 110px; }

.mb-115 {
  margin-bottom: 115px; }

.mb-120 {
  margin-bottom: 120px; }

.mb-125 {
  margin-bottom: 125px; }

.mb-130 {
  margin-bottom: 130px; }

.mb-135 {
  margin-bottom: 135px; }

.mb-140 {
  margin-bottom: 140px; }

.mb-145 {
  margin-bottom: 145px; }

.mb-150 {
  margin-bottom: 150px; }

.mb-155 {
  margin-bottom: 155px; }

.mb-160 {
  margin-bottom: 160px; }

.mb-165 {
  margin-bottom: 165px; }

.mb-170 {
  margin-bottom: 170px; }

.mb-175 {
  margin-bottom: 175px; }

.mb-180 {
  margin-bottom: 180px; }

.mb-185 {
  margin-bottom: 185px; }

.mb-190 {
  margin-bottom: 190px; }

.mb-195 {
  margin-bottom: 195px; }

.mb-200 {
  margin-bottom: 200px; }

.mb-205 {
  margin-bottom: 205px; }

.mb-210 {
  margin-bottom: 210px; }

.mb-215 {
  margin-bottom: 215px; }

.mb-220 {
  margin-bottom: 220px; }

.mb-225 {
  margin-bottom: 225px; }

/* ======= Margin Left ======= */
.ml-5 {
  margin-left: 5px; }

.ml-10 {
  margin-left: 10px; }

.ml-15 {
  margin-left: 15px; }

.ml-20 {
  margin-left: 20px; }

.ml-25 {
  margin-left: 25px; }

.ml-30 {
  margin-left: 30px; }

.ml-35 {
  margin-left: 35px; }

.ml-40 {
  margin-left: 40px; }

.ml-45 {
  margin-left: 45px; }

.ml-50 {
  margin-left: 50px; }

.ml-55 {
  margin-left: 55px; }

.ml-60 {
  margin-left: 60px; }

.ml-65 {
  margin-left: 65px; }

.ml-70 {
  margin-left: 70px; }

.ml-75 {
  margin-left: 75px; }

.ml-80 {
  margin-left: 80px; }

.ml-85 {
  margin-left: 85px; }

.ml-90 {
  margin-left: 90px; }

.ml-95 {
  margin-left: 95px; }

.ml-100 {
  margin-left: 100px; }

.ml-105 {
  margin-left: 105px; }

.ml-110 {
  margin-left: 110px; }

.ml-115 {
  margin-left: 115px; }

.ml-120 {
  margin-left: 120px; }

.ml-125 {
  margin-left: 125px; }

.ml-130 {
  margin-left: 130px; }

.ml-135 {
  margin-left: 135px; }

.ml-140 {
  margin-left: 140px; }

.ml-145 {
  margin-left: 145px; }

.ml-150 {
  margin-left: 150px; }

.ml-155 {
  margin-left: 155px; }

.ml-160 {
  margin-left: 160px; }

.ml-165 {
  margin-left: 165px; }

.ml-170 {
  margin-left: 170px; }

.ml-175 {
  margin-left: 175px; }

.ml-180 {
  margin-left: 180px; }

.ml-185 {
  margin-left: 185px; }

.ml-190 {
  margin-left: 190px; }

.ml-195 {
  margin-left: 195px; }

.ml-200 {
  margin-left: 200px; }

.ml-205 {
  margin-left: 205px; }

.ml-210 {
  margin-left: 210px; }

.ml-215 {
  margin-left: 215px; }

.ml-220 {
  margin-left: 220px; }

.ml-225 {
  margin-left: 225px; }

/* ======= Margin Right ======= */
.mr-5 {
  margin-right: 5px; }

.mr-10 {
  margin-right: 10px; }

.mr-15 {
  margin-right: 15px; }

.mr-20 {
  margin-right: 20px; }

.mr-25 {
  margin-right: 25px; }

.mr-30 {
  margin-right: 30px; }

.mr-35 {
  margin-right: 35px; }

.mr-40 {
  margin-right: 40px; }

.mr-45 {
  margin-right: 45px; }

.mr-50 {
  margin-right: 50px; }

.mr-55 {
  margin-right: 55px; }

.mr-60 {
  margin-right: 60px; }

.mr-65 {
  margin-right: 65px; }

.mr-70 {
  margin-right: 70px; }

.mr-75 {
  margin-right: 75px; }

.mr-80 {
  margin-right: 80px; }

.mr-85 {
  margin-right: 85px; }

.mr-90 {
  margin-right: 90px; }

.mr-95 {
  margin-right: 95px; }

.mr-100 {
  margin-right: 100px; }

.mr-105 {
  margin-right: 105px; }

.mr-110 {
  margin-right: 110px; }

.mr-115 {
  margin-right: 115px; }

.mr-120 {
  margin-right: 120px; }

.mr-125 {
  margin-right: 125px; }

.mr-130 {
  margin-right: 130px; }

.mr-135 {
  margin-right: 135px; }

.mr-140 {
  margin-right: 140px; }

.mr-145 {
  margin-right: 145px; }

.mr-150 {
  margin-right: 150px; }

.mr-155 {
  margin-right: 155px; }

.mr-160 {
  margin-right: 160px; }

.mr-165 {
  margin-right: 165px; }

.mr-170 {
  margin-right: 170px; }

.mr-175 {
  margin-right: 175px; }

.mr-180 {
  margin-right: 180px; }

.mr-185 {
  margin-right: 185px; }

.mr-190 {
  margin-right: 190px; }

.mr-195 {
  margin-right: 195px; }

.mr-200 {
  margin-right: 200px; }

.mr-205 {
  margin-right: 205px; }

.mr-210 {
  margin-right: 210px; }

.mr-215 {
  margin-right: 215px; }

.mr-220 {
  margin-right: 220px; }

.mr-225 {
  margin-right: 225px; }

/* ======= Padding Top ======= */
.pt-5 {
  padding-top: 5px; }

.pt-10 {
  padding-top: 10px; }

.pt-15 {
  padding-top: 15px; }

.pt-20 {
  padding-top: 20px; }

.pt-25 {
  padding-top: 25px; }

.pt-30 {
  padding-top: 30px; }

.pt-35 {
  padding-top: 35px; }

.pt-40 {
  padding-top: 40px; }

.pt-45 {
  padding-top: 45px; }

.pt-50 {
  padding-top: 50px; }

.pt-55 {
  padding-top: 55px; }

.pt-60 {
  padding-top: 60px; }

.pt-65 {
  padding-top: 65px; }

.pt-70 {
  padding-top: 70px; }

.pt-75 {
  padding-top: 75px; }

.pt-80 {
  padding-top: 80px; }

.pt-85 {
  padding-top: 85px; }

.pt-90 {
  padding-top: 90px; }

.pt-95 {
  padding-top: 95px; }

.pt-100 {
  padding-top: 100px; }

.pt-105 {
  padding-top: 105px; }

.pt-110 {
  padding-top: 110px; }

.pt-115 {
  padding-top: 115px; }

.pt-120 {
  padding-top: 120px; }

.pt-125 {
  padding-top: 125px; }

.pt-130 {
  padding-top: 130px; }

.pt-135 {
  padding-top: 135px; }

.pt-140 {
  padding-top: 140px; }

.pt-145 {
  padding-top: 145px; }

.pt-150 {
  padding-top: 150px; }

.pt-155 {
  padding-top: 155px; }

.pt-160 {
  padding-top: 160px; }

.pt-165 {
  padding-top: 165px; }

.pt-170 {
  padding-top: 170px; }

.pt-175 {
  padding-top: 175px; }

.pt-180 {
  padding-top: 180px; }

.pt-185 {
  padding-top: 185px; }

.pt-190 {
  padding-top: 190px; }

.pt-195 {
  padding-top: 195px; }

.pt-200 {
  padding-top: 200px; }

.pt-205 {
  padding-top: 205px; }

.pt-210 {
  padding-top: 210px; }

.pt-215 {
  padding-top: 215px; }

.pt-220 {
  padding-top: 220px; }

.pt-225 {
  padding-top: 225px; }

/* ======= Padding Bottom ======= */
.pb-5 {
  padding-bottom: 5px; }

.pb-10 {
  padding-bottom: 10px; }

.pb-15 {
  padding-bottom: 15px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-25 {
  padding-bottom: 25px; }

.pb-30 {
  padding-bottom: 30px; }

.pb-35 {
  padding-bottom: 35px; }

.pb-40 {
  padding-bottom: 40px; }

.pb-45 {
  padding-bottom: 45px; }

.pb-50 {
  padding-bottom: 50px; }

.pb-55 {
  padding-bottom: 55px; }

.pb-60 {
  padding-bottom: 60px; }

.pb-65 {
  padding-bottom: 65px; }

.pb-70 {
  padding-bottom: 70px; }

.pb-75 {
  padding-bottom: 75px; }

.pb-80 {
  padding-bottom: 80px; }

.pb-85 {
  padding-bottom: 85px; }

.pb-90 {
  padding-bottom: 90px; }

.pb-95 {
  padding-bottom: 95px; }

.pb-100 {
  padding-bottom: 100px; }

.pb-105 {
  padding-bottom: 105px; }

.pb-110 {
  padding-bottom: 110px; }

.pb-115 {
  padding-bottom: 115px; }

.pb-120 {
  padding-bottom: 120px; }

.pb-125 {
  padding-bottom: 125px; }

.pb-130 {
  padding-bottom: 130px; }

.pb-135 {
  padding-bottom: 135px; }

.pb-140 {
  padding-bottom: 140px; }

.pb-145 {
  padding-bottom: 145px; }

.pb-150 {
  padding-bottom: 150px; }

.pb-155 {
  padding-bottom: 155px; }

.pb-160 {
  padding-bottom: 160px; }

.pb-165 {
  padding-bottom: 165px; }

.pb-170 {
  padding-bottom: 170px; }

.pb-175 {
  padding-bottom: 175px; }

.pb-180 {
  padding-bottom: 180px; }

.pb-185 {
  padding-bottom: 185px; }

.pb-190 {
  padding-bottom: 190px; }

.pb-195 {
  padding-bottom: 195px; }

.pb-200 {
  padding-bottom: 200px; }

.pb-205 {
  padding-bottom: 205px; }

.pb-210 {
  padding-bottom: 210px; }

.pb-215 {
  padding-bottom: 215px; }

.pb-220 {
  padding-bottom: 220px; }

.pb-225 {
  padding-bottom: 225px; }

/* ======= Padding Left ======= */
.pl-5 {
  padding-left: 5px; }

.pl-10 {
  padding-left: 10px; }

.pl-15 {
  padding-left: 15px; }

.pl-20 {
  padding-left: 20px; }

.pl-25 {
  padding-left: 25px; }

.pl-30 {
  padding-left: 30px; }

.pl-35 {
  padding-left: 35px; }

.pl-40 {
  padding-left: 40px; }

.pl-45 {
  padding-left: 45px; }

.pl-50 {
  padding-left: 50px; }

.pl-55 {
  padding-left: 55px; }

.pl-60 {
  padding-left: 60px; }

.pl-65 {
  padding-left: 65px; }

.pl-70 {
  padding-left: 70px; }

.pl-75 {
  padding-left: 75px; }

.pl-80 {
  padding-left: 80px; }

.pl-85 {
  padding-left: 85px; }

.pl-90 {
  padding-left: 90px; }

.pl-95 {
  padding-left: 95px; }

.pl-100 {
  padding-left: 100px; }

.pl-105 {
  padding-left: 105px; }

.pl-110 {
  padding-left: 110px; }

.pl-115 {
  padding-left: 115px; }

.pl-120 {
  padding-left: 120px; }

.pl-125 {
  padding-left: 125px; }

.pl-130 {
  padding-left: 130px; }

.pl-135 {
  padding-left: 135px; }

.pl-140 {
  padding-left: 140px; }

.pl-145 {
  padding-left: 145px; }

.pl-150 {
  padding-left: 150px; }

.pl-155 {
  padding-left: 155px; }

.pl-160 {
  padding-left: 160px; }

.pl-165 {
  padding-left: 165px; }

.pl-170 {
  padding-left: 170px; }

.pl-175 {
  padding-left: 175px; }

.pl-180 {
  padding-left: 180px; }

.pl-185 {
  padding-left: 185px; }

.pl-190 {
  padding-left: 190px; }

.pl-195 {
  padding-left: 195px; }

.pl-200 {
  padding-left: 200px; }

.pl-205 {
  padding-left: 205px; }

.pl-210 {
  padding-left: 210px; }

.pl-215 {
  padding-left: 215px; }

.pl-220 {
  padding-left: 220px; }

.pl-225 {
  padding-left: 225px; }

/* ======= Padding Right ======= */
.pr-5 {
  padding-right: 5px; }

.pr-10 {
  padding-right: 10px; }

.pr-15 {
  padding-right: 15px; }

.pr-20 {
  padding-right: 20px; }

.pr-25 {
  padding-right: 25px; }

.pr-30 {
  padding-right: 30px; }

.pr-35 {
  padding-right: 35px; }

.pr-40 {
  padding-right: 40px; }

.pr-45 {
  padding-right: 45px; }

.pr-50 {
  padding-right: 50px; }

.pr-55 {
  padding-right: 55px; }

.pr-60 {
  padding-right: 60px; }

.pr-65 {
  padding-right: 65px; }

.pr-70 {
  padding-right: 70px; }

.pr-75 {
  padding-right: 75px; }

.pr-80 {
  padding-right: 80px; }

.pr-85 {
  padding-right: 85px; }

.pr-90 {
  padding-right: 90px; }

.pr-95 {
  padding-right: 95px; }

.pr-100 {
  padding-right: 100px; }

.pr-105 {
  padding-right: 105px; }

.pr-110 {
  padding-right: 110px; }

.pr-115 {
  padding-right: 115px; }

.pr-120 {
  padding-right: 120px; }

.pr-125 {
  padding-right: 125px; }

.pr-130 {
  padding-right: 130px; }

.pr-135 {
  padding-right: 135px; }

.pr-140 {
  padding-right: 140px; }

.pr-145 {
  padding-right: 145px; }

.pr-150 {
  padding-right: 150px; }

.pr-155 {
  padding-right: 155px; }

.pr-160 {
  padding-right: 160px; }

.pr-165 {
  padding-right: 165px; }

.pr-170 {
  padding-right: 170px; }

.pr-175 {
  padding-right: 175px; }

.pr-180 {
  padding-right: 180px; }

.pr-185 {
  padding-right: 185px; }

.pr-190 {
  padding-right: 190px; }

.pr-195 {
  padding-right: 195px; }

.pr-200 {
  padding-right: 200px; }

.pr-205 {
  padding-right: 205px; }

.pr-210 {
  padding-right: 210px; }

.pr-215 {
  padding-right: 215px; }

.pr-220 {
  padding-right: 220px; }

.pr-225 {
  padding-right: 225px; }

/* ======= bg-primary shades ========= */
.bg-primary-100 {
  background: rgba(74, 108, 247, 0.1); }

.bg-primary-200 {
  background: rgba(74, 108, 247, 0.2); }

.bg-primary-300 {
  background: rgba(74, 108, 247, 0.3); }

.bg-primary-400 {
  background: rgba(74, 108, 247, 0.4); }

.bg-primary-500 {
  background: rgba(74, 108, 247, 0.5); }

.bg-primary-600 {
  background: rgba(74, 108, 247, 0.6); }

.bg-primary-700 {
  background: rgba(74, 108, 247, 0.7); }

.bg-primary-800 {
  background: rgba(74, 108, 247, 0.8); }

.bg-primary-900 {
  background: rgba(74, 108, 247, 0.9); }

/* ======= bg-secondary shades ========= */
.bg-secondary-100 {
  background: rgba(0, 193, 248, 0.1); }

.bg-secondary-200 {
  background: rgba(0, 193, 248, 0.2); }

.bg-secondary-300 {
  background: rgba(0, 193, 248, 0.3); }

.bg-secondary-400 {
  background: rgba(0, 193, 248, 0.4); }

.bg-secondary-500 {
  background: rgba(0, 193, 248, 0.5); }

.bg-secondary-600 {
  background: rgba(0, 193, 248, 0.6); }

.bg-secondary-700 {
  background: rgba(0, 193, 248, 0.7); }

.bg-secondary-800 {
  background: rgba(0, 193, 248, 0.8); }

.bg-secondary-900 {
  background: rgba(0, 193, 248, 0.9); }

/* ======= bg-success shades ========= */
.bg-success-100 {
  background: rgba(33, 150, 83, 0.1); }

.bg-success-200 {
  background: rgba(33, 150, 83, 0.2); }

.bg-success-300 {
  background: rgba(33, 150, 83, 0.3); }

.bg-success-400 {
  background: rgba(33, 150, 83, 0.4); }

.bg-success-500 {
  background: rgba(33, 150, 83, 0.5); }

.bg-success-600 {
  background: rgba(33, 150, 83, 0.6); }

.bg-success-700 {
  background: rgba(33, 150, 83, 0.7); }

.bg-success-800 {
  background: rgba(33, 150, 83, 0.8); }

.bg-success-900 {
  background: rgba(33, 150, 83, 0.9); }

/* ======= bg-danger shades ========= */
.bg-danger-100 {
  background: rgba(213, 1, 0, 0.1); }

.bg-danger-200 {
  background: rgba(213, 1, 0, 0.2); }

.bg-danger-300 {
  background: rgba(213, 1, 0, 0.3); }

.bg-danger-400 {
  background: rgba(213, 1, 0, 0.4); }

.bg-danger-500 {
  background: rgba(213, 1, 0, 0.5); }

.bg-danger-600 {
  background: rgba(213, 1, 0, 0.6); }

.bg-danger-700 {
  background: rgba(213, 1, 0, 0.7); }

.bg-danger-800 {
  background: rgba(213, 1, 0, 0.8); }

.bg-danger-900 {
  background: rgba(213, 1, 0, 0.9); }

/* ======= bg-warning shades ========= */
.bg-warning-100 {
  background: rgba(247, 200, 0, 0.1); }

.bg-warning-200 {
  background: rgba(247, 200, 0, 0.2); }

.bg-warning-300 {
  background: rgba(247, 200, 0, 0.3); }

.bg-warning-400 {
  background: rgba(247, 200, 0, 0.4); }

.bg-warning-500 {
  background: rgba(247, 200, 0, 0.5); }

.bg-warning-600 {
  background: rgba(247, 200, 0, 0.6); }

.bg-warning-700 {
  background: rgba(247, 200, 0, 0.7); }

.bg-warning-800 {
  background: rgba(247, 200, 0, 0.8); }

.bg-warning-900 {
  background: rgba(247, 200, 0, 0.9); }

/* ======= bg-info shades ========= */
.bg-info-100 {
  background: rgba(151, 202, 49, 0.1); }

.bg-info-200 {
  background: rgba(151, 202, 49, 0.2); }

.bg-info-300 {
  background: rgba(151, 202, 49, 0.3); }

.bg-info-400 {
  background: rgba(151, 202, 49, 0.4); }

.bg-info-500 {
  background: rgba(151, 202, 49, 0.5); }

.bg-info-600 {
  background: rgba(151, 202, 49, 0.6); }

.bg-info-700 {
  background: rgba(151, 202, 49, 0.7); }

.bg-info-800 {
  background: rgba(151, 202, 49, 0.8); }

.bg-info-900 {
  background: rgba(151, 202, 49, 0.9); }

/* ======= bg-dark shades ========= */
.bg-dark-100 {
  background: rgba(38, 45, 63, 0.1); }

.bg-dark-200 {
  background: rgba(38, 45, 63, 0.2); }

.bg-dark-300 {
  background: rgba(38, 45, 63, 0.3); }

.bg-dark-400 {
  background: rgba(38, 45, 63, 0.4); }

.bg-dark-500 {
  background: rgba(38, 45, 63, 0.5); }

.bg-dark-600 {
  background: rgba(38, 45, 63, 0.6); }

.bg-dark-700 {
  background: rgba(38, 45, 63, 0.7); }

.bg-dark-800 {
  background: rgba(38, 45, 63, 0.8); }

.bg-dark-900 {
  background: rgba(38, 45, 63, 0.9); }

/* ======= bg-purple shades ========= */
.bg-purple-100 {
  background: rgba(155, 81, 224, 0.1); }

.bg-purple-200 {
  background: rgba(155, 81, 224, 0.2); }

.bg-purple-300 {
  background: rgba(155, 81, 224, 0.3); }

.bg-purple-400 {
  background: rgba(155, 81, 224, 0.4); }

.bg-purple-500 {
  background: rgba(155, 81, 224, 0.5); }

.bg-purple-600 {
  background: rgba(155, 81, 224, 0.6); }

.bg-purple-700 {
  background: rgba(155, 81, 224, 0.7); }

.bg-purple-800 {
  background: rgba(155, 81, 224, 0.8); }

.bg-purple-900 {
  background: rgba(155, 81, 224, 0.9); }

/* ======= bg-orange shades ========= */
.bg-orange-100 {
  background: rgba(242, 153, 74, 0.1); }

.bg-orange-200 {
  background: rgba(242, 153, 74, 0.2); }

.bg-orange-300 {
  background: rgba(242, 153, 74, 0.3); }

.bg-orange-400 {
  background: rgba(242, 153, 74, 0.4); }

.bg-orange-500 {
  background: rgba(242, 153, 74, 0.5); }

.bg-orange-600 {
  background: rgba(242, 153, 74, 0.6); }

.bg-orange-700 {
  background: rgba(242, 153, 74, 0.7); }

.bg-orange-800 {
  background: rgba(242, 153, 74, 0.8); }

.bg-orange-900 {
  background: rgba(242, 153, 74, 0.9); }

/* ======== Background Colors ========== */
.primary-bg {
  background-color: #4a6cf7; }

.secondary-bg {
  background-color: #00c1f8; }

.success-bg {
  background-color: #219653; }

.danger-bg {
  background-color: #d50100; }

.warning-bg {
  background-color: #f7c800; }

.info-bg {
  background-color: #97ca31; }

.dark-bg {
  background-color: #262d3f; }

.light-bg {
  background-color: #efefef; }

.active-bg {
  background-color: #4a6cf7; }

.deactive-bg {
  background-color: #cbe1ff; }

.deactive-bg {
  background-color: #cbe1ff; }

.gray-bg {
  background-color: #5d657b; }

.purple-bg {
  background-color: #9b51e0; }

.orange-bg {
  background-color: #f2994a; }

.deep-blue-bg {
  background-color: #345d9d; }

/* ======== Text Colors ========== */
.text-primary {
  color: #4a6cf7 !important; }

.text-secondary {
  color: #00c1f8 !important; }

.text-success {
  color: #219653 !important; }

.text-danger {
  color: #d50100 !important; }

.text-warning {
  color: #f7c800 !important; }

.text-info {
  color: #97ca31 !important; }

.text-dark {
  color: #262d3f !important; }

.text-light {
  color: #efefef !important; }

.text-active {
  color: #4a6cf7 !important; }

.text-deactive {
  color: #cbe1ff !important; }

.text-deactive {
  color: #cbe1ff !important; }

.text-gray {
  color: #5d657b !important; }

.text-orange {
  color: #f2994a !important; }

/* ========= Font Weight =========== */
.fw-300 {
  font-weight: 300; }

.fw-400 {
  font-weight: 400; }

.fw-500 {
  font-weight: 500; }

.fw-600 {
  font-weight: 600; }

.fw-700 {
  font-weight: 700; }

.fw-800 {
  font-weight: 800; }

.fw-900 {
  font-weight: 900; }


/**
  --------------Custom Styles For Table Hovering-----------------
*/

/* Dropdown Button */
.dropbtn {
  color: black;
  font-size: 16px;
  cursor: pointer
}

.dropdown {
  position: relative;
  display: inline-block;

  z-index: 9;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: .8rem;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}


/**---------Preloader Styling-------------*/

.Loader {
  border-radius: 500px;
  border: 3px solid #ccc;
  border-left-color: #0083db;
  display: inline-block;
  vertical-align: middle;
  height: 35px;
  width: 35px;
}

.Loader--fill {
  border-color: #0083db;    
}

.Loader--lg {
  width: 50px;
  height: 50px;
}

@keyframes pulse {
  0% {
    transform: scale(.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.is-pulsing {
  animation-name: pulse;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

.preloader {
    margin: 30px auto;
    text-align: center;
}

/* Single Investor Admin Field */

.input-style-4 {
  display: flex;
  margin-bottom: 30px; 
  align-items: center;
}

.input-style-4 label {
    font-size: 14px;
    font-weight: 500;
    color: #262d3f;
    display: block;
    margin-bottom: 10px; 
    margin-left: 10px;
    margin-top: 10px;
}
  .input-style-1 input,
  .input-style-1 textarea {
    width: 100%;
    background: rgba(239, 239, 239, 0.5);
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 16px;
    color: #5d657b;
    resize: none;
    transition: all 0.3s; }
    .input-style-1 input:focus,
    .input-style-1 textarea:focus {
      border-color: #4a6cf7;
      background: #fff; }
    .input-style-1 input[type="date"], .input-style-1 input[type="time"],
    .input-style-1 textarea[type="date"],
    .input-style-1 textarea[type="time"] {
      background: transparent; }

/** Direct Cash Deposit Form*/
.account_selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account_selection div {
  display: flex;
  align-items: center;
}

.account_selection span {
  display: flex;
  align-items: center;
}

.account_selection span input {
  margin-left: 20px;
}

.account_selection div input {
  margin-left: 20px;
}

.passport_container {
  width: 120px;
  height: 100px;
  border: 1px solid black;
}

.account_number_cscs input {
  width: 60%;
  height: 30px;
  margin-top: 10px;
}

.account_phone {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account_box {
  display: flex;
  align-items: center;
}

.account_box h6 {
  margin-right: 20px;
}

.account_box .account__options span {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.account_box .account__options span input {
  width: 50px
}

.horizontal__line {
  margin: 10px 0px;
  font-weight: bold;
}

.password-divs {
  max-width: 100%;
}

.download_btn > div > button > a {
  color: #5d657b;
}

.download_btn > div > button:hover > a {
  color: #ffffff
}

.download_btn > div > a.disabled {
  pointer-events: none;
  cursor: default;
}

@media only screen and (max-width: 992px) {
    .trade-btns {
      display: flex;
    }

    /* .trade-btns button {
      margin-bottom: 10px
    } */

    .investor-header {
      display: flex !important;
      flex-direction: column !important;
      justify-content: flex-start !important;
    }
};

@media only screen and (min-width: 775px) {


}
